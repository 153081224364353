//upload x-ray image to AWS S.3
import { Fragment, useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation
} from "react-router-dom";
import { InputGroup } from 'react-bootstrap';

async function postImage({ image, sonumber, orderId, company ,type}) {
  console.log(image);
  console.log(sonumber);
  console.log(orderId);
  console.log(company);
  console.log(type);
  const formData = new FormData();
  formData.append("image", image)
  formData.append("description", sonumber)
  formData.append("order_id", orderId)
  formData.append("companyname", company)
  formData.append("type", type)
  const result = await axios.post('https://iacss.smtglobal.com.hk:3001/api/images', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  return result.data
}

const Uploadimage = () => {

  const { query } = useLocation();

  const [file, setFile] = useState()
  const [files, setFiles] = useState([]);
  //const [description, setDescription] = useState("")
  const [description, setDescription] = useState(query.sonumber)
  const [images, setImages] = useState([])
  const [order_id, setOrder_id] = useState(query.order_id)
  const [companyname, setCompanyname] = useState(query.companyname)
  //console.log('staff page upload: ', order_id, query)

  const notify = () => toast.success('File uploaded! 檔案已上傳!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

    const fileSelected = event => {
      const file = event.target.files
      var fileList = []
      for (let i = 0; i < file.length; i++) {
        fileList[i] = file[i]
      }
      console.log(fileList)
      setFiles(fileList)
    //  setFlag("1");
    }

  const submit = async event => {
    event.preventDefault()
    for (let i = 0; i < files.length; i++) {
      const result = await postImage({ image: files[i], sonumber:description ,orderId:order_id ,company:companyname , type:"Move-In"})
      setImages([result.image, ...images])
    }
    setDescription("")
  }

  return (
    <div>
      <div>
      <ToastContainer position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover/>
      </div>
      <br></br>
      <h3>Upload上傳</h3>
      <br></br>
    <div className="App">
      <form onSubmit={submit}>
      <InputGroup>
        <input onChange={fileSelected} type="file"  multiple accept="image/*"></input>
        <input value={description} maxlength="8" onChange={e => setDescription(e.target.value)} type="text"></input>
        <InputGroup.Append>
        <button type="submit" className="btn btn-dark" onClick= {notify} >Submit 提交</button></InputGroup.Append>
        </InputGroup>
      </form>
    </div>
    <br></br>
    <p align = "right">
      <Link to="/staff/orderlist">
      <button type="button" className="btn btn-dark ml-3">
        Back to Order List 返回訂單表
      </button>
      </Link>
      </p>
    </div>
  );
};

export default Uploadimage;
