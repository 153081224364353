import React, { Fragment, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { InputGroup, Container, Row, Col, Button, Form } from "react-bootstrap";

const Input = ({ setChange, companylist }) => {
    const [sonumber, setSonumber] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [companyId, setCompanyId] = useState("");
    const baseUrl = `https://iacss.smtglobal.com.hk:3001`
    var regEx = /^(?!\s)[A-Z0-9\s]+$/i;

    const notify = () => toast.success('Order added 己增加', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const setCompanyDetail = (companyname) => {
        setCompanyname(companyname);
        console.log("test:"+companylist.filter(lists => lists.username == companyname));
        companylist.filter(lists => lists.username == companyname).map(list => {
            console.log(list.uid);
            setCompanyId(list.uid)
        });
      }

    const onSubmitForm = async e => {
        e.preventDefault();
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("jwt_token", localStorage.token);
            const body = { sonumber, companyname , companyId};
            if (sonumber == "" || companyname == "") {
                alert("Please input SO number and company name! 請輸入SO number和公司名稱!")
            } else if (regEx.test(sonumber) === false) {
                alert("Please input correct SO number!")
            } else {
                var isExistingOrder = false;
                const response1 = await fetch(baseUrl + `/api/orderlist/staffitem/${companyname}/${sonumber}`);
                const jsonData1 = await response1.json();
                if (jsonData1.length > 0) {
                    isExistingOrder = true;
                }

        
                if(isExistingOrder){
                    alert("Please add the information via multi-edit.請使用多項編輯中新增相關資料。");
                }else{
                    const response = await fetch("https://iacss.smtglobal.com.hk:3001/api/orderlist", {
                        method: "POST",
                        headers: myHeaders,
                        body: JSON.stringify(body),
    
                    });
                    const parseResponse = await response.json();
                    console.log(parseResponse);
                    alert("Order added 訂單己增加");
                }
                setChange(true);
            }
        } catch (err) {
            console.error(err.message);
        }
    };
    return (
        <Fragment>
            <h1 className="text-center mt-5">Order List</h1>

            {<form className="d-flex mt-5" onSubmit={onSubmitForm}>
                <input
                    type="text"
                    className="form-control"
                    placeholder="SO number"
                    maxlength="250"
                    value={sonumber.toUpperCase()}
                    onChange={e => setSonumber(e.target.value)}
                />
                <Form.Control
                    name="company"
                    value={companyname}
                    className="form-control"
                    onChange={e => {
                        setCompanyDetail(e.target.value);
                    }}
                    as="select"
                >
                    <option value=""> -- Select an option 選擇 --</option>
                    {companylist.map(company => (
                        <option key={company.username} value={company.username}>{company.display_name}</option>

                    ))}
                </Form.Control>
                <button className="btn btn-dark">Add 新增</button>

            </form>}
        </Fragment>
    );
};

export default Input;
