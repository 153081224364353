//upload x-ray image to AWS S.3
import { Fragment, useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation, useHistory
} from "react-router-dom";
import { InputGroup, Row, Col, Form, Button } from "react-bootstrap";
import { produce } from "immer";
import deleteLogo from './img/delete.png'


const OperatorPalletSizeInputMain = () => {

  const { query } = useLocation();
  const [sonumber, setDescription] = useState("")
  const [order_id, setOrder_id] = useState(0)
  const [userType, setUserType] = useState("")
  const [increment, setIncrement] = useState(0)
  const [companyname, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [weight, setWeight] = useState(0)
  const [palletItems, setPalletItems] = useState([]);
  const [bulkRecordUid, setBulkRecordUid] = useState("")
  const [readOnly, setReadOnly] = useState(true)
  const [change, setChange] = useState(false)
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);


  const baseUrl = `https://iacss.smtglobal.com.hk:3001`

  const initQuery = async () => {
    if (query != null || typeof (query) !== "undefined") {
      setDescription(query.sonumber)
      setUserType(query.userType)
      setIncrement(query.increment)
      setCompanyName(query.companyname)
      setCompanyDisplayName(query.companyDisplayName)
      setOrder_id(query.order_id)
      setBulkRecordUid(query.bulkRecordUid)
      setWeight(query.weight)
      fetchItemMeasurement()
    } else {
      window.location.reload();
    }
  }

  const fetchItemMeasurement = async () => {
    try {
      console.log("fetchItemMeasurement")
      const response = await fetch(baseUrl + `/api/iacss/get_item_measurements/${query.bulkRecordUid}`);
      const jsonData = await response.json();
      console.log(jsonData)
      setPalletItems(jsonData);
      itemInputDefaultInit()
    } catch (err) {
      setChange(!change)
      console.error(err.message);
    }
  };

  const confirm = async (e) => {
    setLoading(true)
    palletItems.filter(item => item.uid != null).map(async item => {
      await updateItemMeasurement(item.uid, item.length, item.width, item.height, item.quantity)
    })

    palletItems.filter(item => item.uid == null).map(async item => {
      await addItemMeasurement(bulkRecordUid, item.length, item.width, item.height, item.quantity)
    })

    await updateBulkRecordWeight(bulkRecordUid, weight)
    await fetchItemMeasurement().then(setLoading(false))
    setHasError(false)
    setReadOnly(true)
  }


  const addItemMeasurement = async (bulkRecordUid, length, width, height, quantity) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("jwt_token", localStorage.token);
      length = length > 0 ? length : 0;
      width = width > 0 ? width : 0;
      height = height > 0 ? height : 0;
      quantity = quantity > 0 ? quantity : 0;
      if(length == 0 && width == 0 && height == 0 && quantity == 0){
        return;
      }
      const itemMeasurementBody = { bulkRecordUid, length, width, height, quantity };
      await fetch(baseUrl + "/api/iacss/create_item_measurements", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(itemMeasurementBody)
      }).then((response) => { if (response.status == 500) { setHasError(true) } })
      setChange(!change)
    } catch (e) {
      setChange(!change)
      console.error(e)
      alert("資料確認失敗,請重新輸入")
    }
  }

  const updateItemMeasurement = async (uid, length, width, height, quantity) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("jwt_token", localStorage.token);
      length = length > 0 ? length : 0;
      width = width > 0 ? width : 0;
      height = height > 0 ? height : 0;
      quantity = quantity > 0 ? quantity : 0;
      if(length == 0 && width == 0 && height == 0 && quantity == 0){
        return;
      }
      const itemMeasurementBody = { uid, length, width, height, quantity };
      const iacssResponse = await fetch(baseUrl + "/api/iacss/update_item_measurements", {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(itemMeasurementBody)
      }).then((response) => { if (response.status == 500) { setHasError(true) } })
      setChange(!change)
    } catch (e) {
      alert("資料確認失敗,請重新輸入")
      setChange(!change)
      console.error(e)
    }
  }

  const updateBulkRecordWeight = async (uid, weight) => {
    try {
      console.log(uid)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("jwt_token", localStorage.token);
      weight = weight > 0 ? weight : 0;
      const bulkRecordBody = { uid, weight };
      const iacssResponse = await fetch(baseUrl + "/api/iacss/update_bulk_record", {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(bulkRecordBody)
      }).then((response) => { if (response.status == 500) { setHasError(true) } })
      setChange(!change)
    } catch (e) {
      alert("資料確認失敗,請重新輸入")
      console.error(e)
    }
  }

  function deleteItem(index) {
    if (index !== -1) {
      const newItems = palletItems.filter((item, index2) => index2 != index)
      newItems.map(item => item.increment = newItems)
      setPalletItems(
        newItems
      )
    }
  }
  
    function itemInputDefaultInit() {
    let itemInputDefault = 0
     for (let i = 0; itemInputDefault < 100 ; i++) {
       setPalletItems(currentOrder => [...currentOrder, { uid: null, bulk_record_uid: bulkRecordUid, increment: 0, length: null, width: null, height: null, quantity: 0, weight: 0 }])
       itemInputDefault++
      }
  }

  useEffect(() => {
    initQuery()
  }, [query]);

  useEffect(() => {
    fetchItemMeasurement()
  }, [change]);

  useEffect(() => {
    console.log(hasError)
    if (hasError == true) {
      console.log(hasError)
      alert("資料確認失敗,請重新嘗試")
    }
  }, [hasError]);


  return (

    <div>
      <br></br>
      <p align="right">
        {userType == "operator" ?
          <Link to={{ pathname: `/operator/operatorImageAndSizeMain/`, query: { sonumber: sonumber, bulkRecordUid: bulkRecordUid, increment: increment, weight: weight, companyDisplayName: companyDisplayName, companyName: companyname, userType: "operator" } }}>
            <button type="button" className="btn btn-dark ml-3">
              Back to previous page <br></br>返回上一頁
            </button>
          </Link> :

          <Link to="/staff/orderlist">
            <button type="button" className="btn btn-dark ml-3">
              Back to Order List 返回訂單表
            </button>
          </Link>
        }
      </p>
      <br></br>
      <h3 style={{ fontWeight: 'bold' }}> Size input 尺寸輸入
      </h3> <br />
      <h4>SO Pallet_id: {sonumber + "-" + increment}</h4> <br />
      <h4>Company 公司: {companyDisplayName}</h4><br />

      <Form>
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalText">
          <Form.Label column sm={2}>
            Weight 重量 (kg):
          </Form.Label>
          <Col sm={5}>
            <Form.Control type="text" value={weight > 0 ? weight : null} onChange={(e) => {
              const validated = e.target.value.match(/^\d{0,5}(?:\.\d{0,4})?$/)
              if (validated != null) {
                setWeight(e.target.value);
              }
            }} />
          </Col>
        </Form.Group>
      </Form>
      <br></br>
      <Row xs={3} align="center" className="align-items-center">
        <Col style={{ padding: 3 }}>
          <button onClick={() => {
            console.log(palletItems.length + 1)
            setPalletItems(currentOrder => [...currentOrder, { uid: null, bulk_record_uid: bulkRecordUid, increment: 0, length: null, width: null, height: null, quantity: 0, weight: 0 }])
          }
          }
            disabled={loading}
            className="btn btn-danger ml-12 col-md-12 ">
            Add 新增
          </button>
        </Col>
        <br></br>



        <Col style={{ padding: 3, margin: 0 }}>
          <button onClick={() => {
            setReadOnly(false)
          }
          }
            disabled={loading}
            className="btn btn-primary ml-8 col-md-12 ">
            modify修改
          </button>
        </Col>

        <Col style={{ padding: 3, margin: 0 }}>
          <button onClick={() => {
            confirm()
          }
          }
            disabled={loading}
            className="btn btn-success ml-12 col-md-12 ">
            Confirm確認
          </button>
        </Col>
        <br></br>
      </Row>

      <br></br><br></br>
      <div>
        <Row xs={5} align="center" className="align-items-center">
          <Col >長(cm)</Col>
          <Col >闊(cm)</Col>
          <Col >高(cm)</Col>
          <Col >數量</Col>
          <Col ></Col>
        </Row>
      </div>

      {palletItems.map((palletItem, index) => (
        <Row xs={5} align="center" className="align-items-center">

          <Col align="center" style={{ padding: 5 }}>
            <input
              type="text"
              pattern="[0-9*]"
              className="form-control col-md-12"
              id={"palletItemL" + index}
              maxlength="9"
              value={palletItem.length}
              onChange={(e) => {
                const validated = e.target.value.match(/^\d{0,3}(?:\.\d{0,1})?$/)
                if (validated != null) {
                  const length = e.target.value
                  const currentlength = palletItem.length; setPalletItems((currentOrder) => produce(currentOrder, v => { v[index].length = length; length == currentlength ? v[index].isDataChange *= 1 : v[index].isDataChange *= -1 }))
                }
              }
              }
              readOnly={readOnly && palletItem.uid != null}
            />
          </Col>

          <Col align="center" style={{ padding: 5 }}>
            <input
              type="text"
              pattern="[0-9*]"
              className="form-control col-md-12"
              id={"palletItemW" + index}
              maxlength="9"
              value={palletItem.width}
              onChange={(e) => {
                const validated = e.target.value.match(/^\d{0,3}(?:\.\d{0,1})?$/)
                if (validated != null) {
                  const width = e.target.value
                  setPalletItems((currentOrder) => produce(currentOrder, v => { v[index].width = width }))
                }
              }}
              readOnly={readOnly && palletItem.uid != null}
            />
          </Col>

          <Col align="center" style={{ padding: 5 }}>
            <input
              type="text"
              pattern="[0-9*]"
              className="form-control col-md-12"
              id={"palletItemH" + index}
              maxlength="9"
              value={palletItem.height}
              onChange={(e) => {
                const validated = e.target.value.match(/^\d{0,3}(?:\.\d{0,1})?$/)
                console.log(e.target.value)
                console.log(validated)
                if (validated != null) {
                  const height = e.target.value
                  console.log(height)
                  setPalletItems((currentOrder) => produce(currentOrder, v => { v[index].height = height }))
                  console.log(palletItem.height)
                }
              }}
              readOnly={readOnly && palletItem.uid != null}
            />
          </Col>

          <Col align="center" style={{ padding: 5 }}>
            <input
              type="text"
              className="form-control col-md-12"
              id={"palletItemQTY" + index}
              maxlength="9"
              value={palletItem.quantity > 0 ? palletItem.quantity : ""}
              onChange={(e) => {
                const validated = e.target.value.match(/^\d*$/)
                if (validated != null) {
                  const quantity = Number(e.target.value)
                  setPalletItems((currentOrder) => produce(currentOrder, v => { v[index].quantity = quantity }))
                }
              }
              }
              readOnly={readOnly && palletItem.uid != null}
            />
          </Col>


          <Col>
            {palletItem.uid == null ?
              <Button variant={"white"} onClick={() => {
                console.log(index)
                if (index !== -1) {
                  setPalletItems(
                    palletItems.filter((item, index2) => index2 != index)
                  )
                }
              }}
              >
                <img src={deleteLogo} width="40" height="40" alt="logo" />
              </Button>
              : null
            }

          </Col>

          <br></br>
          <br></br>
        </Row>
      )
      )}

    </div >
  );
};

export default OperatorPalletSizeInputMain;
