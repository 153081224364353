import React, { Fragment, useState, useEffect } from "react";
import { produce } from "immer";
import { InputGroup, Container, Row, Col, Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import {
  Link, useLocation
} from "react-router-dom";
import deleteLogo from './img/delete.png'

const DeleteImages = ({ apiData, userType, username, searchSonumber, moveInDeleteChecked, weighingDeleteChecked, IACSSChecked, xRayDeleteChecked, selectedSingleImages, setImagesDeleted, imagesDeleted }) => {


  const baseUrl = `https://iacss.smtglobal.com.hk:3001`
  const [show, setShow] = useState();
  const [orderlist, setOrder] = useState([]);
  var colour = userType=="staff"? "white": "#bfdfe3";


  const handleClose = () => {
    if (setImagesDeleted != null && imagesDeleted != null) {
      setImagesDeleted(!imagesDeleted);
    }
    setShow(false);
  }
  const handleShow = () => {

    setShow(true);
  }

  const deletedNotify = () => toast.success('Deleted all images! 全部圖片已刪除!', {
    toastId: 2,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    //draggable: true,
    progress: undefined
  });

  const noDeletedNotify = () => toast.warning('No image can be deleted! 沒有可刪除的圖片!', {
    toastId: 2,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    //draggable: true,
    progress: undefined
  });

  const is_deletedImage = async key => {
    try {
      const deleteTodo = await fetch(baseUrl + `/api/images/is_delete/${key}`, {
        method: "PUT"
      });

      console.log("Image deleted, key:" + key);
    } catch (err) {
      console.error(err.message);
    }
  };

  const deletePhotos = () => {

    try {
      console.log("photos delete now")
      console.log(apiData)
      let images = selectedSingleImages && selectedSingleImages.length > 0 ? selectedSingleImages : apiData;

      if (typeof (moveInDeleteChecked) !== 'undefined' && !moveInDeleteChecked) {
        images = images.filter(images => images.type != "Move-In");
      }

      if (typeof (weighingDeleteChecked) !== 'undefined' && !weighingDeleteChecked) {
        images = images.filter(images => images.type != "Weighing");
      }

      if (typeof (IACSSChecked) !== 'undefined' && !IACSSChecked) {
        images = images.filter(images => images.type != "Surface");
      }

      if (typeof (xRayDeleteChecked) !== 'undefined' && !xRayDeleteChecked) {
        images = images.filter(images => images.type != "X-Ray");
      }

      if (images && images.length > 0) {
        if (typeof (images[0].isChecked) !== 'undefined' && images[0].isChecked != null) {
          images = images.filter(images => images.isChecked == true);
        }
      }



      console.log(images)
      if(images.length>0){
        deletedNotify();
      }else{
        noDeletedNotify();
      }

      for (var i = 0; i < images.length; i++) {
        is_deletedImage(images[i].key);
      }

    } catch (e) {
      console.log("delete photos fail")
    }

    handleClose();
  }


  return (
    <Fragment>
      {userType != "client" ?
        <Button variant={colour} style={{ color: 'red' }} onClick={handleShow}>
          <img src={deleteLogo} width="50" height="50" alt="logo" />
          Delete 刪除
        </Button> : null

      }
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm  確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div> Confirm Delete photos?
          </div>

          <div> 確定刪除相片?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" data-dismiss="modal" onClick={deletePhotos}>
            Confirm 確定
          </Button>


          <Button variant="secondary" onClick={handleClose}>
            Cancel 取消
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default DeleteImages;
