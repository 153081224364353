import './App.css';
import React, { Component, Fragment, useEffect, useState } from "react";
import { InputGroup, Container, Row, Col, Button, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import home from "./components/home";
//import aboutus from "./components/aboutus";
//port contact from "./components/contact";
import LoginClient from "./components/LoginClient";
import LoginStaff from "./components/LoginStaff";
//import Register from './components/register';
import Uploadimage from './components/uploadimage';
import ViewImage from './components/viewImage';
import SoNumberHistory from './components/soNumberHistory';
import MutiEditPage from './components/mutiEditPage';
import Clientpage from './components/clientpage';
import Orderlist from './components/orderlist';
import ViewFolderImage from './components/viewFolderImage';
import LoginOperator from './components/LoginOperator';
import LoginImage from './components/LoginImage';
import OperatorPage from './components/operatorpage'
import OperatorImageMain from './components/operatorImageMain';
import OperatorImageAndSizeMain from './components/operatorImageAndSizeMain';
import OperatorPalletSizeInputMain from './components/operatorPalletSizeInputMain';
import OperatorPalletMain from './components/operatorPalletMain';
import OperatorFolderImage from './components/operatorFolderImage';
import OperatorFolderItemImage from './components/operatorFolderItemImage';
import OperatorImageUpload from './components/operatorImageUpload';
import OperatorItemImageUpload from './components/operatorItemImageUpload';
import CopyrightFooter from "./components/CopyrightFooter";
import Imagepage from './components/imagepage'
import './App.css'

function App() {
  const checkAuthenticated = async (req, res) => {

    try {
      // const { username } = req.params;

	    const res 
            = await fetch(
                "https://iacss.smtglobal.com.hk:3001/api/auth/verify/staff", {
                    method: "GET",
                        headers: { jwt_token: localStorage.token }
                }
            );
        const parseRes = await res.json();

      if (parseRes === true) {
        setIsAuthenticated(true);

      } else {
        setIsAuthenticated(false);
        console.log("ok");
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const checkAuthenticated1 = async (req, res) => {

    try {
      // const { username } = req.params;

        const res 
            = await fetch(
                "https://iacss.smtglobal.com.hk:3001/api/auth/verify/client", {
                    method: "GET",
                    headers: { jwt_token: localStorage.token }
                }
            );
        const parseRes1 = await res.json();

      if (parseRes1 === true) {
        setIsAuthenticated1(true);

      } else {
        setIsAuthenticated1(false);
        console.log("ok");
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const checkAuthenticated2 = async (req, res) => {

    try {
      // const { username } = req.params;

        const res 
            = await fetch(
                "https://iacss.smtglobal.com.hk:3001/api/auth/verify/operator", {
                    method: "GET",
                    headers: { jwt_token: localStorage.token }
                }
            );
        const parseRes1 = await res.json();

      if (parseRes1 === true) {
        setIsAuthenticated2(true);

      } else {
        setIsAuthenticated2(false);
        console.log("ok");
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticated1, setIsAuthenticated1] = useState(false);
  const [isAuthenticated2, setIsAuthenticated2] = useState(false);
  const [username, setUsername] = useState("");


  useEffect(() => {
    checkAuthenticated();
    checkAuthenticated1();
    checkAuthenticated2();
  }, []);


  const setAuth = boolean => {
    setIsAuthenticated(boolean);
  };

  const setAuth1 = boolean => {
    setIsAuthenticated1(boolean);
  };

  const setAuth2 = boolean => {
    setIsAuthenticated2(boolean);
  };

  const setUser = (user) => {
    console.log(user);
    setUsername(user);
  }

  const setColor = (newColor) => {
    console.log(newColor);
    document.documentElement.style.setProperty('--root_colour', newColor);
    document.documentElement.style.setProperty('--root_div_colour', newColor);
  }
  // <div className="App" style={username == "client" ? { backgroundColor: '#bfdfe3' } : (username == "operator" ? { backgroundColor: '#f7d8ab' } : null)}></div>
  //style={username == "client" ? { backgroundColor: '#bfdfe3', height: 'auto' } : (username == "operator" ? { backgroundColor: '#f7d8ab', height: 'auto' } : {backgroundColor: '#white', height: 'auto' })}
  return (
    <Fragment>
      <div className="root-div">

        <div>
          <ToastContainer position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </div>

        {!isAuthenticated && !isAuthenticated1 && !isAuthenticated2 && typeof (localStorage.token) == "undefined" ?
          <Navbar variant="dark" bg="dark">
            <Container>
              <Navbar.Brand href="/home">SMT</Navbar.Brand>

              <Nav className="me-auto">
                <NavDropdown title="Login" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/client/login">Client Login 顧客登入</NavDropdown.Item>
                  <NavDropdown.Item href="/staff/login">Staff Login 職員登入</NavDropdown.Item>
                  <NavDropdown.Item href="/operator/login">Operator Login 操作員登入</NavDropdown.Item>
                  <NavDropdown.Item href="/image/login">Inspection page Login 相片頁登入</NavDropdown.Item>
                </NavDropdown>
              </Nav>

            </Container>
          </Navbar> :

          isAuthenticated1 && username == "client" && typeof (localStorage.token) !== "undefined" ?

            <Navbar variant="dark" bg="dark">
              <Container>
                <Navbar.Brand href="/home">SMT</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Navbar.Brand>Welcome 歡迎 {localStorage.username}!</Navbar.Brand>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar> :
            isAuthenticated && username == "staff" && typeof (localStorage.token) !== "undefined" ?

              <Navbar variant="dark" bg="dark">
                <Container>
                  <Navbar.Brand href="/home">SMT</Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav >
                      <Navbar.Brand>Welcome 歡迎 {localStorage.userAccount}!</Navbar.Brand>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar> :

              isAuthenticated2 && username == "operator" && typeof (localStorage.token) !== "undefined" ?
                <Navbar variant="dark" bg="dark">
                  <Container>
                    <Navbar.Brand href="/home">SMT</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        <Navbar.Brand>Welcome 歡迎 Operator!</Navbar.Brand>
                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </Navbar> :
                <Navbar variant="dark" bg="dark">
                  <Container>
                    <Navbar.Brand href="/home">SMT</Navbar.Brand>

                    <Nav className="me-auto">
                      <NavDropdown title="Login" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/client/login">Client Login 顧客登入</NavDropdown.Item>
                        <NavDropdown.Item href="/staff/login">Staff Login 職員登入</NavDropdown.Item>
                        <NavDropdown.Item href="/operator/login">Operator Login 操作員登入</NavDropdown.Item>
                        <NavDropdown.Item href="/image/login">Inspection page Login 相片頁登入</NavDropdown.Item>
                      </NavDropdown>
                    </Nav>

                  </Container>
                </Navbar>
        }

        <Container>
          <div className="container">
            <Router>
              <Switch>
                <Route exact path="/">  {<Redirect to="/home" />} </Route>
                <Route exact path={["/home"]} component={home} />
                {/* <Route exact path="/register" component={Register} /> */}
                {/* ------------- */}
                <Route
                  exact
                  path="/client/login"
                  render={props =>
                    !isAuthenticated1 ? (
                      <LoginClient {...props} setAuth1={setAuth1} setUser={setUser} setColor={setColor} />
                    ) : (
                      <Redirect to="/client/ordertracking" />
                    )
                  }
                />
                {/* ------------- */}
                <Route exact
                  path="/client/ordertracking"
                  render={props =>
                    isAuthenticated1 ? (
                      <Clientpage {...props} setAuth1={setAuth1} />
                    ) : (
                      <Redirect to="/client/login" />
                    )
                  }
                />

                {/* ------------- */}
                <Route exact
                  path="/client/viewImage"
                  render={props =>
                    isAuthenticated1 ? (
                      <ViewImage {...props} setAuth={setAuth1} />
                    ) : (
                      <Redirect to="/client/login" />
                    )
                  }
                />

                {/* ------------- */}
                <Route exact
                  path="/client/viewFolderImage"
                  render={props =>
                    isAuthenticated1 ? (
                      <ViewFolderImage {...props} setAuth={setAuth1} />
                    ) : (
                      <Redirect to="/client/login" />
                    )
                  }
                />

                {/* ------------- */}
                <Route exact
                  path="/staff/login"
                  render={props =>
                    !isAuthenticated ? (
                      <LoginStaff {...props} setAuth={setAuth} setUser={setUser} />
                    ) : (
                      <Redirect to="/staff/orderlist" />
                    )
                  }
                />
                {/* ------------- */}
                <Route exact
                  path="/staff/orderlist"
                  render={props =>
                    isAuthenticated ? (
                      <Orderlist {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />
                {/* ------------- */}
                <Route exact
                  path="/staff/uploadimage"
                  render={props =>
                    isAuthenticated ? (
                      <Uploadimage {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />

                {/* ------------- */}
                <Route exact
                  path="/staff/viewFolderImage"
                  render={props =>
                    isAuthenticated ? (
                      <ViewFolderImage {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />

                <Route exact
                  path="/staff/viewImage"
                  render={props =>
                    isAuthenticated ? (
                      <ViewImage {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />

                <Route exact
                  path="/staff/operatorImageMain"
                  render={props =>
                    isAuthenticated ? (
                      <OperatorImageMain {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />

                <Route exact
                  path="/staff/operatorFolderImage"
                  render={props =>
                    isAuthenticated ? (
                      <OperatorFolderImage {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />

                <Route exact
                  path="/staff/operatorImageUpload"
                  render={props =>
                    isAuthenticated ? (
                      <OperatorImageUpload {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />

                {/* ------------- */}
                <Route exact
                  path="/staff/soNumberHistory"
                  render={props =>
                    isAuthenticated ? (
                      <SoNumberHistory {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />

                {/* ------------- */}
                <Route exact
                  path="/staff/mutiEditPage"
                  render={props =>
                    isAuthenticated ? (
                      <MutiEditPage {...props} setAuth={setAuth} />
                    ) : (
                      <Redirect to="/staff/login" />
                    )
                  }
                />

                <Route
                  exact
                  path="/operator/login"
                  render={props =>
                    !isAuthenticated2 ? (
                      <LoginOperator {...props} setAuth2={setAuth2} setUser={setUser} setColor={setColor} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />

                {/* ------------- */}
                <Route exact
                  path="/operator/operatorPage"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorPage {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/login" />
                    )
                  }
                />


                <Route exact
                  path="/operator/operatorImageMain"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorImageMain {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />

                <Route exact
                  path="/operator/operatorImageAndSizeMain"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorImageAndSizeMain {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />

                <Route exact
                  path="/operator/operatorPalletMain"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorPalletMain {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />

                <Route exact
                  path="/operator/operatorPalletSizeInputMain"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorPalletSizeInputMain {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />


                <Route exact
                  path="/operator/operatorFolderImage"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorFolderImage {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />

                <Route exact
                  path="/operator/operatorFolderItemImage"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorFolderItemImage {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />

                <Route exact
                  path="/operator/operatorImageUpload"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorImageUpload {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />

                <Route exact
                  path="/operator/operatorItemImageUpload"
                  render={props =>
                    isAuthenticated2 ? (
                      <OperatorItemImageUpload {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/operator/operatorPage" />
                    )
                  }
                />

                {/* ------------- */}

                <Route
                  exact
                  path="/image/login"
                  render={props =>
                    !isAuthenticated2 ? (
                      <LoginImage {...props} setAuth2={setAuth2} setUser={setUser} setColor={setColor} />
                    ) : (
                      <Redirect to="/image/imagePage" />
                    )
                  }
                />

                <Route exact
                  path="/image/imagePage"
                  render={props =>
                    isAuthenticated2 ? (
                      <Imagepage {...props} setAuth2={setAuth2} />
                    ) : (
                      <Redirect to="/image/login" />
                    )
                  }
                />

              </Switch>
            </Router>
          </div>
        </Container>
        <CopyrightFooter />
      </div>

    </Fragment>
  );


}

export default App;
