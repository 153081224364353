//upload x-ray image to AWS S.3
import { Fragment, useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation, useHistory
} from "react-router-dom";
import { InputGroup, Container, Row, Col, Modal, Button, NavItem, ButtonGroup, ToggleButton } from "react-bootstrap";
import folderLogo from './img/folder_2.png'

const OperatorImageMain = () => {

  const { query } = useLocation();


  //const [sonumber, setDescription] = useState(query.sonumber)
  //const [userType, setUserType] = useState(query.userType)
  //const [order_id, setOrder_id] = useState(query.order_id)
  // const [remark, setRemark] = useState(query.remark)
  //const [companyname, setCompanyName] = useState(query.companyName)
  // const [orderlist, setOrder] = useState(query.orderlist);

  const [sonumber, setDescription] = useState("")
  const [userType, setUserType] = useState("")
  const [order_id, setOrder_id] = useState(0)
  const [remark, setRemark] = useState("")
  const [companyname, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [companyId, setCompanyId] = useState(0);
  const [orderlist, setOrder] = useState("");

  const [flag, setFlag] = useState("0");
  const [images, setImages] = useState([])
  const [checked, setChecked] = useState(false);
  const [moveInDeleteChecked, setMoveInDeleteChecked] = useState(false);
  const [weighingDeleteChecked, setweighingDeleteChecked] = useState(false);
  const [IACSSChecked, setIACSSChecked] = useState(false);
  const [xRayDeleteChecked, setXRayDeleteChecked] = useState(false);
  const [weight, setWeight] = useState(0);

  const baseUrl = `https://iacss.smtglobal.com.hk:3001`
  const history = useHistory()

  const initQuery = async () => {
    if (query != null || typeof (query) !== "undefined") {
      console.log(query);
      console.log(query.order_id);
      setDescription(query.sonumber)
      setUserType(query.userType)
      let orderIdForIacss;
      if (typeof (query.order_id) !== "undefined") {
        setOrder_id(query.order_id)
        orderIdForIacss = query.order_id;
      } else if (typeof (query.orderlist) !== "undefined" && query.orderlist != null && query.orderlist.length > 0) {
        setOrder_id(query.orderlist[0].order_id)
        orderIdForIacss = query.orderlist[0].order_id;
      }else{
        orderIdForIacss = await initOrder(query.companyName,query.companyId,query.sonumber)
      }
      initIacssOrder(query.companyName,query.sonumber,query.weight, orderIdForIacss)
      setRemark(query.remark)
      setCompanyName(query.companyName)
      setCompanyDisplayName(query.companyDisplayName)
      setCompanyId(query.companyId)
      setWeight(query.weight)
      setOrder(query.orderlist)
    } else {
      window.location.reload();
    }
  }

  const initOrder = async (companyname,companyId ,sonumber) => {
    const response1 = await fetch(baseUrl + `/api/orderlist/staffitem/${companyname}/${sonumber}`);
    const jsonData1 = await response1.json();
    if (jsonData1.length > 0) {
      console.log("order find:" + jsonData1)
      setOrder(jsonData1);
      setOrder_id(jsonData1[0].order_id)
    }
    // If no related records , create a new one

    if (!(jsonData1.length > 0)) {
      console.log("Creat new order")
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("jwt_token", localStorage.token);
      const body = { sonumber, companyname,companyId};
  

      const response = await fetch("https://iacss.smtglobal.com.hk:3001/api/orderlist", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),

      });

      const parseResponse = await response.json();
      setOrder(parseResponse);
      setOrder_id(parseResponse.order_id)
      console.log(parseResponse)
      return parseResponse.order_id;
    }
  }

  const initIacssOrder = async (companyname,sonumber, weight, orderIdForIacss) => {
    try{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt_token", localStorage.token);
    const iacssBody = { sonumber, companyname,weight, orderIdForIacss};
    const iacssResponse = await fetch("https://iacss.smtglobal.com.hk:3001/api/so_samples", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(iacssBody),

    });

    console.log(iacssResponse)
  }catch(e){
    console.error(e)
  }
  }

  useEffect(() => {
    initQuery()
  }, [query]);

  /*
  useEffect(() => {
    initOrder()
  }, [flag]);
*/
  return (

    <div>
      <br></br>
      <h3> View {"&"} Upload Images 檢視及上傳相片
      </h3> <br />
      <h3>SO number: {sonumber}</h3> <br />
      <h3>Company 公司: {companyDisplayName}</h3>
      <br></br>


      <br></br>
      <p align="right">
        {userType == "operator" ?
          <Link to="/operator/operatorPage">
            <button type="button" className="btn btn-dark ml-3">
              Back to Operator Insert Page <br></br>返回操作員輸入頁面
            </button>
          </Link> :

          <Link to="/staff/orderlist">
            <button type="button" className="btn btn-dark ml-3">
              Back to Order List 返回訂單表
            </button>
          </Link>
        }
      </p>


      <br></br>
      <br></br>


      <Link to={{ pathname: `/${userType}/operatorFolderImage/`, query: { order_id: order_id, sonumber: sonumber, companyDisplayName:companyDisplayName ,companyName: companyname, userType: userType, photoType: "Move-In" } }}>
        <Button variant="#f7d8ab" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>Move in 入倉</Button>
      </Link>



      <Link to={{ pathname: `/${userType}/operatorFolderImage/`, query: { order_id: order_id, sonumber: sonumber,companyDisplayName:companyDisplayName , companyName: companyname, userType: userType, photoType: "Weighing" } }}>
        <Button variant="#f7d8ab" style={{ marginLeft: '2.5rem' }} > <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>Weighing 磅相</Button>
      </Link>

      {userType == "staff" ?
        <Link to={{ pathname: `/${userType}/operatorFolderImage/`, query: { order_id: order_id, sonumber: sonumber,companyDisplayName:companyDisplayName , companyName: companyname, userType: userType, photoType: "Surface" } }}>
          <Button variant="#f7d8ab" style={{ marginLeft: '2.5rem' }} > <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>IACSS 系統</Button>
        </Link> :
        null
      }

      {userType == "staff" ?
        <Link to={{ pathname: `/${userType}/operatorFolderImage/`, query: { order_id: order_id, sonumber: sonumber,companyDisplayName:companyDisplayName , companyName: companyname, userType: userType, photoType: "X-Ray" } }}>
          <Button variant="#f7d8ab" style={{ marginLeft: '2.5rem' }} > <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>X-ray X光</Button>
        </Link>
        :
        null
      }


     



    </div >


  );
};

export default OperatorImageMain;
