import React, { Fragment, useState, useEffect } from "react";
import { produce } from "immer";
import { InputGroup, Container, Row, Col, Modal, Button, Toast } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation
} from "react-router-dom";
import downloadLogo from './img/download.png'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as JSZip from 'jszip';

const DownloadImages = ({ apiData, userType, searchSonumber, moveInDeleteChecked, weighingDeleteChecked, IACSSChecked, xRayDeleteChecked, selectedSingleImages }) => {


  const baseUrl = `https://iacss.smtglobal.com.hk:3001`
  const [show, setShow] = useState();
  const [orderlist, setOrder] = useState([]);
  var colour = userType=="staff"? "white": "#bfdfe3";




  const handleClose = () => {

    setShow(false);
  }
  const handleShow = () => {

    setShow(true);
  }

  const downloadingNotify = () => toast.success('Images downloading! 圖片下載中!', {
    toastId: 1,
    position: "top-center",
    autoClose: 30000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    // draggable: true,
    progress: undefined
  });

  const downloadedNotify = () => toast.success('Images downloaded! 圖片已下載!', {
    toastId: 2,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    //draggable: true,
    progress: undefined
  });

  const downloadedWarnNotify = () => toast.warning('Success, no Images download! 己成功,沒圖片下載!', {
    toastId: 2,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    //draggable: true,
    progress: undefined
  });

  const downloadedFailNotify = () => toast.error('Images download fail! 圖片下載失敗!', {
    toastId: 2,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    //draggable: true,
    progress: undefined
  });

  const notifyClose = (id) => toast.dismiss(id);


  const downloadPhotos = () => {

    try {
      console.log("photos downloading")
      console.log(apiData)
      console.log(moveInDeleteChecked)
      console.log(weighingDeleteChecked)
      console.log(IACSSChecked)
      console.log(xRayDeleteChecked)
      let images = selectedSingleImages && selectedSingleImages.length > 0 ? selectedSingleImages : apiData;
      let links = [
        // "https://iacss.smtglobal.com.hk:3001/api/images/fd5d0a3ac61aa329faa0d72d5d2b1ee9"
      ]
      if (typeof (moveInDeleteChecked) !== 'undefined' && !moveInDeleteChecked) {
        console.log("moveInDeleteChecked")
        images = images.filter(images => images.type != "Move-In");
        console.log(images)
      }

      if (typeof (weighingDeleteChecked) !== 'undefined' && !weighingDeleteChecked) {
        console.log("weighingDeleteChecked")
        images = images.filter(images => images.type != "Weighing");
      }

      if (typeof (IACSSChecked) !== 'undefined' && !IACSSChecked) {
        images = images.filter(images => images.type != "Surface");
      }

      if (typeof (xRayDeleteChecked) !== 'undefined' && !xRayDeleteChecked) {
        images = images.filter(images => images.type != "X-Ray");
      }

      if (images && images.length > 0) {
        if (typeof (images[0].isChecked) !== 'undefined' && images[0].isChecked != null) {
          images = images.filter(images => images.isChecked == true);
        }
      }



      console.log(images)

      for (var i = 0; i < images.length; i++) {
        links[i] = "https://iacss.smtglobal.com.hk:3001/api/images/" + images[i].key
      }
      console.log(links)
      if (links.length < 1) { downloadedWarnNotify() }

      var zip = new JSZip();
      var count = 0;
      var zipFilename = searchSonumber+".zip";
      links.forEach(function (url, i) {
        var filename = links[i];
        filename = i + ".jpg";
        // loading a file and add it in a zip file
        try {
          let blob = fetch(url).then(r => r.blob()).catch(err => {
            console.log("download photos fail")
          });
          zip.file(filename, blob, { binary: true });
        } catch (e) {
          downloadedFailNotify()
          console.log("download photos fail")
        }


        count++;
        if (count == links.length) {
          downloadingNotify();
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            notifyClose(1);
            FileSaver.saveAs(content, zipFilename);
            console.log("downloadedNotify")
            downloadedNotify();
          });
        }

      });
    } catch (e) {
      downloadedFailNotify()
      console.log("download photos fail")
    }

    handleClose()
  }


  return (
    <Fragment>

      <Button variant={colour} style={{ color: 'green' }} onClick={handleShow}>
        <img src={downloadLogo} width="50" height="50" alt="logo" />
        Download 下載
      </Button>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm  確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div> Confirm download.
          </div>

          <div> 確認下載。
          </div>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" data-dismiss="modal" onClick={downloadPhotos}>
            Confirm 確定
          </Button>


          <Button variant="secondary" onClick={handleClose}>
            Cancel 取消
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default DownloadImages;
