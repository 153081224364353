//upload x-ray image to AWS S.3
import { Fragment, useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation
} from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";



const OperatorFolderItemImage = () => {

  const { query } = useLocation();

  const [description, setDescription] = useState("")
  const [userType, setUserType] = useState("")
  const [photoType, setPhotoType] = useState()
  const [images, setImages] = useState([])
  const [order_id, setOrder_id] = useState(0)
  const [companyName, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [increment, setIncrement] = useState(0)
  const [bulkRecordUid, setBulkRecordUid] = useState("")
  const [weight, setWeight] = useState("")
  const [flag, setFlag] = useState("0");


  const initQuery = async () => {
    if (query != null || typeof (query) !== "undefined") {
      console.log(query);
      console.log("id+" + query.order_id);
      setIncrement(query.increment)
      setBulkRecordUid(query.bulkRecordUid)
      setDescription(query.sonumber)
      setUserType(query.userType)
      setOrder_id(query.order_id)
      setPhotoType(query.photoType)
      setCompanyName(query.companyName)
      setCompanyDisplayName(query.companyDisplayName)
      setWeight(query.weight)
    } else {
      window.location.reload();
    }
  }

  const imagename = async () => {
    try {
      var type = ""
      if (photoType == "Move-In") {
        type = "Move-In"
      } else if (photoType == "Weighing") {
        type = "Weighing"
      } else if (photoType == "Surface") {
        type = "Surface"
      } else if (photoType == "X-Ray") {
        type = "X-Ray"
      }

      console.log(type)

      const response1 = await fetch(`https://iacss.smtglobal.com.hk:3001/api/bulkImage/${query.bulkRecordUid}/${type}`);
      const jsonData1 = await response1.json();
      console.log(jsonData1)
      setImages(jsonData1);
      setFlag("1");
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    initQuery()
  }, [query]);

  useEffect(() => {
    imagename();
  }, [photoType]);

  return (

    <div>
      <div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </div>
      <br></br>

 
      <p align="right">
        {userType == "operator" ?
          <Link to={{ pathname: `/operator/operatorImageAndSizeMain/`, query: { sonumber: description, bulkRecordUid: bulkRecordUid, increment: increment, weight: weight, companyDisplayName: companyDisplayName, companyName: companyName, userType: "operator" } }}>
            <button type="button" className="btn btn-dark ml-3">
              Back to previous page <br></br>返回上一頁
            </button>
          </Link> :
          <Link to={{ pathname: `/staff/operatorImageMain`, query: { sonumber: description, companyDisplayName: companyDisplayName, companyName: companyName, orderlist: [], userType: "staff" } }}>
            <button type="button" className="btn btn-dark ml-3">
              Back to the previous page 返回上一頁
            </button>
          </Link>
        }
      </p>


      <Row>
        <Col>
          <h3>{photoType == "Move-In" ? "Move in Photos 入倉相片" : null}
            {photoType == "Weighing" ? "Weighing Photos 磅相" : null}
            {photoType == "Surface" ? " IACSS Photos 相機相片" : null}
            {photoType == "X-Ray" ? "X-ray Photos X光相片" : null}
          </h3>
        </Col>

      </Row>
      <br></br>
      <h3>SO pallet_id: {description + "-" + increment}</h3> <br />
      <h3>Company 公司: {companyDisplayName}</h3>
      <br></br>


      <br></br>
      <Col>
        <p align="right">
          <Link to={{ pathname: `/${userType}/operatorItemImageUpload/`, query: { order_id: order_id, bulkRecordUid: bulkRecordUid, increment: increment, sonumber: description, companyDisplayName: companyDisplayName, companyName: companyName, userType: userType, weight: weight, photoType: photoType } }}>
            <Button variant="success" >
              Upload images <br></br>上傳相片
            </Button>
          </Link>
        </p>
      </Col>


      <br></br>
      <br></br>

      <Row>
        {images.map(image =>
        (

          <Col xs="auto" sm={3}>
            <div key={image}>
              <img src={`https://iacss.smtglobal.com.hk:3001/api/images/${image.s3_key}`} style={{ marginTop: "15%" }} width="150" height="150" />
            </div>
          </Col>

        ))}
      </Row>

    </div>


  );
};

export default OperatorFolderItemImage;
