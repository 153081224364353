import React, { Fragment, useEffect, useState } from "react";
import { Table, Row, Col, Button, Form } from "react-bootstrap";
import OperatorModifyConfirmPop from "./operatorModifyConfirmPop";
import OperatorAddConfirmPop from "./operatorAddConfirmPop";

const OperatorPage = ({ setAuth2 }) => {
    const baseUrl = `https://iacss.smtglobal.com.hk:3001`
    const [company, setCompany] = useState("");
    const [companyDisplayName, setCompanyDisplayName] = useState("");
    const [companyId, setCompanyId] = useState(0);
    const [companylist, setCompanylist] = useState([]);
    const [salesOrderlist, setSalesOrderlist] = useState([]);
    const [change, setChange] = useState(false);
    const [searchSonumber, setSearchSonumber] = useState("");



    const reset = async () => {
        setSearchSonumber("")
        setCompany("")
        setChange(!change)
    }

    const setSearchInfo = async (soNumber, company) => {
        setSearchSonumber(soNumber)
        setCompanyDetail(company)
    }


    function handleCallback() {
        setChange(!change)
    }


    const setCompanyDetail = async (company) => {
        setCompany(company)
        var companyDisplayName = companylist.filter(c => c.username == company);
        console.log(company);
        console.log(companyDisplayName);
        if (companyDisplayName.length > 0) {
            setCompanyDisplayName(companyDisplayName[0].display_name);
            setCompanyId(companyDisplayName[0].uid)
        }

    }

    const getLatestSalesOrder = async () => {
        try {
            const res = await fetch(baseUrl + `/api/iacss/sales_order/latest10`);
            const jsonData = await res.json();
            console.log(jsonData);
            setSalesOrderlist(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    };

    const getCompany = async () => {
        try {
            const res = await fetch(baseUrl + `/api/company/name`);
            const jsonData = await res.json();

            setCompanylist(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    };

    const convertToHKT = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
          timeZone: 'Asia/Hong_Kong',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        };
    
        return date.toLocaleString('en-US', options);
      };

    useEffect(() => {
        getCompany();
        getLatestSalesOrder();
    }, [change]);


    const logout = async e => {
        try {
            e.preventDefault();
            localStorage.removeItem("token");
            setAuth2(false);
        } catch (err) {
            console.error(err.message);
        }

    };


    return (
        <Fragment>

            <Row>

                <Col sm={10}>
                    <h1 align="left" >Operator Insert Page 操作員輸入頁面</h1>
                </Col>

                <Col sm={2} >
                    <p align="right">
                        <button onClick={e => logout(e)} className="btn btn-primary">
                            Operator Logout <br></br> 操作員登出
                        </button>
                    </p>
                </Col>

            </Row>

            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalText">
                    <Form.Label column sm={2}>
                        SO number:
                    </Form.Label>
                    <Col sm={5}>
                        <Form.Control type="text" value={searchSonumber} onChange={e => {
                            setSearchSonumber(e.target.value.toUpperCase());
                        }} />
                    </Col>
                </Form.Group>


                <Form.Group as={Row} className="mb-3" controlId="formHorizontalSelect">
                    <Form.Label column sm={3}>Select company 選擇公司:</Form.Label>
                    <Col sm={5}>
                        <Form.Control
                            name="company"
                            value={company}
                            className="form-control"
                            onChange={e => {
                                setCompanyDetail(e.target.value);
                            }}
                            as="select"
                        >
                            <option value=""> -- Select an option 選擇 --</option>
                            {companylist.map(company => (
                                <option key={company.username} value={company.username}>{company.display_name}</option>

                            ))}
                        </Form.Control>
                    </Col>
                </Form.Group>
            </Form>


            <Row>
                <p align="right">
                    <OperatorAddConfirmPop sonumber={searchSonumber} company={company} companyDisplayName={companyDisplayName} companyId={companyId} handleCallback={handleCallback} /> &nbsp;
                    <OperatorModifyConfirmPop sonumber={searchSonumber} company={company} companyDisplayName={companyDisplayName} companyId={companyId} /> &nbsp;

                    <Button sm={2} variant="dark"
                        onClick={() => reset()}
                    >Reset 重置</Button>

                </p>
            </Row>
            <br></br>
            <br></br>
            <Table responsive>
                <thead>
                    <tr align="center">
                        <th>SO number</th>
                        <th>company</th>
                        <th>Update time更新時間</th>
                    </tr>
                </thead>
                <tbody align="center">
                    {salesOrderlist.map(orderlist => (
                        <tr align="center">
                            <td>{
                                <Button variant="link" onClick={() => setSearchInfo(orderlist.so_number, orderlist.name)}>
                                    {orderlist.so_number}
                                </Button>
                            }</td>
                            <td>{orderlist.name}</td>
                            <td>{convertToHKT(orderlist.last_update_time)}</td>

                        </tr>

                    ))}
                </tbody>
            </Table>



        </Fragment>
    );
}

export default OperatorPage;
