import React, { Fragment, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const Delete_alert = ({ order_id, sonumber, userAccount, order_data_source, setChange }) => {
  //const [sonumber, setInput] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmitForm = async e => {
    e.preventDefault();
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("jwt_token", localStorage.token);
      const body = { sonumber };
    } catch (err) {
      console.error(err.message);
    }
  };

  const is_deleteTodo = async () => {
    //alert('Check out!!!')
    try {
      const deleteTodo 
        = await fetch(
          `https://iacss.smtglobal.com.hk:3001/api/orderlist/is_delete/${order_id}`, 
          {
            method: "PUT"
          });

      //filter out the pressed "order id", not delete that "order id"
      //setOrder(orderlist.filter(orderlist => orderlist.order_id !== id));

      setShow(false);
      setChange(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Fragment>
      {/*<h1 className="text-center mt-5">Order List</h1>

            {<form className="d-flex mt-5" onSubmit={onSubmitForm}>
            <input
              type="text"
              className="form-control"
              maxlength="8"
              value={sonumber}
              onChange={e => setInput(e.target.value)}
            />
            <button className="btn btn-dark">Add</button>

            </form>} */}

      <Button style={{height: '40px', width : '120px'}} variant={order_data_source == "SOCOMP" ? 'secondary' : 'danger'} onClick={order_data_source == "SOCOMP" ? null : handleShow} disabled={order_data_source == "SOCOMP" ? true : false}>
        Delete 刪除
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete 刪除</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to delete this SO number, {sonumber} ?
        </Modal.Body>
        <Modal.Body>
          你確定要刪除這個 SO number, {sonumber} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No 否
          </Button>
          <Button variant="danger" onClick={is_deleteTodo}>Yes 是</Button>
        </Modal.Footer>
      </Modal>

    </Fragment>
  );
};

export default Delete_alert;
