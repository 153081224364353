//upload x-ray image to AWS S.3
import { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation,useHistory
} from "react-router-dom";
import { InputGroup, Container, Row, Col, Modal, Button, NavItem, ButtonGroup } from "react-bootstrap";
import folderLogo from './img/folder_2.png'
import DownloadImages from './downloadImages';
import DeleteImages from './deleteImages';

const ViewFolderImage = () => {

  const { query } = useLocation();


  //const [description, setDescription] = useState("")
  const [description, setDescription] = useState("")
  const [userType, setUserType] = useState("")
  const [photoType, setPhotoType] = useState("")
  const [images, setImages] = useState([])
  const [order_id, setOrder_id] = useState(0)
  const [remark, setRemark] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [awb, setAwb] = useState("")
  const [flag, setFlag] = useState("0");
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [imagesDeleted, setImagesDeleted] = useState(false);
  //console.log('staff page upload: ', order_id, query)
  //var processImages = images.map(images => ({ ...images, isChecked: false }));
  const history = useHistory();

  const initQuery = async () => {
    if (query != null || typeof (query) !== "undefined") {
      console.log(query.userType);
      setDescription(query.sonumber)
      setUserType(query.userType)
      setPhotoType(query.photoType)
      if (typeof (query.order_id) !== "undefined") {
        setOrder_id(query.order_id)
      } else if (typeof (query.orderlist) !== "undefined" && query.orderlist != null && query.orderlist.length > 0) {
        setOrder_id(query.orderlist[0].order_id)
      }
      setAwb(query.awb)
      setRemark(query.remark)
      setCompanyName(query.companyName)
      setCompanyDisplayName(query.companyDisplayName)
      
    } else {
     // window.location.reload();
     if(localStorage.userAccount == "staff" || localStorage.userAccount == "admin"){
      history.push({ pathname: `/staff/orderlist/` });
     }else if(localStorage.userAccount == "client"){
      history.push({ pathname: `/client/ordertracking/` });
     }
      
    }
  }


  const imagename = async () => {
    try {
      var type = ""
      if (photoType == "Move-In") {
        type = "Move-In"
      } else if (photoType == "Weighing") {
        type = "Weighing"
      } else if (photoType == "Surface") {
        type = "Surface"
      } else if (photoType == "X-Ray") {
        type = "X-Ray"
      }

      if(description =="" || companyName=="" || type==""){
        return;
      }
      
      const response1 = await fetch(`https://iacss.smtglobal.com.hk:3001/api/imageKeys/${description}/${companyName}/${type}`);
      const jsonData1 = await response1.json();
      console.log(jsonData1)
      setImages(jsonData1.map(images => ({ ...images, isChecked: false })));
    } catch (err) {
      console.error(err.message);
    }
  };

  const refresh = async () => {
    for (var i = 0; i < images.length; i++) {
      if (typeof (document.getElementById('toggle-check' + i)) != "undefined" && document.getElementById('toggle-check' + i) != null) {
        console.log(document.getElementById('toggle-check' + i))
        document.getElementById('toggle-check' + i).checked = false;
      }
    }
    setIsCheckBox(false);
  }


  const imageHandling = (id) => {
    let currentImages = images;
    console.log(currentImages);
    console.log(id);
    console.log(currentImages[id].isChecked);
    currentImages[id].isChecked = !currentImages[id].isChecked;
    console.log(currentImages[id].isChecked);
    setImages(currentImages);
    for (let i = 0; i < currentImages.length; i++) {
      if (currentImages[i].isChecked) {
        setIsCheckBox(true)
        break;
      }
      setIsCheckBox(false)
    }
  }


  useEffect(() => {
    initQuery();
  }, [query]);

  useEffect(() => {
    imagename();
    refresh();
  }, [imagesDeleted,description]);


  return (

    <div>
      <div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </div>
      <br></br>

      <h3>{photoType == "Move-In" ? "Move in Photos 入倉相片" : null}
        {photoType == "Weighing" ? "Weighing Photos 磅相" : null}
        {photoType == "Surface" ? " IACSS Photos 相機相片" : null}
        {photoType == "X-Ray" ? "X-ray Photos X光相片" : null}
      </h3> <br></br>
      <h3>SO number: {description}</h3> <br />
      {userType == ("staff"|| "admin") ?
        <h3>Company 公司: {companyDisplayName}</h3>
        : <h3>AWB: {awb}</h3>
      }
      <br></br>


      <br></br>
      <p align="right">
        {userType == "staff" ?
          <Link to={{ pathname: `/staff/viewImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName, companyDisplayName:companyDisplayName,awb: awb, userType: "staff" } }}>
            <button type="button" className="btn btn-dark ml-3">
              Back to the previous page 返回上一頁
            </button>
          </Link>
          :
          <Link to={{ pathname: `/client/viewImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName, awb: awb, userType: "client" } }}>
            <button type="button" className="btn btn-dark ml-3">
              Back to the previous page 返回上一頁
            </button>
          </Link>
        }
      </p>

      <Row >
        <Col xs="auto">
          <label for="inputAWB">Remarks 備註:</label> &nbsp;
        </Col>
        <Col sm={9} >
          <input
            type="text"
            className="form-control col-md-10"
            id="inputRemark"
            maxlength="30"
            value={remark}
            // onChange={e => setRemark(e.target.value)}
            readOnly={true}
          />
        </Col>
      </Row>
      <br></br>

      {isCheckBox ?
        <Row>
          <p align="right">
            <DeleteImages apiData={images} userType={userType} username={companyName} searchSonumber={description} setImagesDeleted={setImagesDeleted} imagesDeleted={imagesDeleted} /> &nbsp;
            <DownloadImages apiData={images} userType={userType} username={companyName} searchSonumber={description} />
          </p>
        </Row>
        : null
      }
      <br></br>


      <Row>
        {images.map((image, idex) =>
        (
          <Col xs="auto" sm={3}>
            <ButtonGroup>
              <div key={image}>
                <img src={`https://iacss.smtglobal.com.hk:3001/api/images/${image.key}`} style={{ marginTop: "15%" }} width="150" height="150" />
              </div>

              <input
                id={"toggle-check" + idex}
                type="checkbox"
                variant="light"
                //  checked={moveInDeleteChecked}
                //value="1"
                onChange={(e) => imageHandling(idex)}
              >
              </input>
            </ButtonGroup>
          </Col>

        ))}
      </Row>

    </div>


  );
};

export default ViewFolderImage;
