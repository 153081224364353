//upload x-ray image to AWS S.3
import { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation
} from "react-router-dom";
import { InputGroup, Container, Row, Col, Modal, Button, NavItem, Form } from "react-bootstrap";
import { produce } from "immer";
import MutiEditConfirmPop from "./mutiEditConfirmPop";
import useScreenType from "react-screentype-hook";



const MutiEditPage = () => {

  const { query } = useLocation();
  const [searchSonumber, setSearch] = useState("");
  const [sonumber, setSonumber] = useState(query.sonumber)
  const [companylist, setCompanylist] = useState(query.companylist);
  const [userAccount, setUserAccount] = useState(localStorage.userAccount);
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [images, setImages] = useState([])
  const [order_id, setOrder_id] = useState(query.order_id)
  const [data, setData] = useState(query.data)
  const [flag, setFlag] = useState("0");
  const [awb, setawb] = useState("");
  const [orderlist, setOrder] = useState([]);
  const [additionalItem, setAdditionalItem] = useState("0");
  const [Change, setChange] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [allMoveOut, setAllMoveOutStatus] = useState(-1);
  const [remark, setRemark] = useState("");
  const [show, setShow] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isShowSearchMessage, setIsShowSearchMessage] = useState(false);
  const screenType = useScreenType({
    mobile: 400,
    tablet: 800,
    desktop: 1400,
    largeDesktop: 1600
  });

  const validation = {
    isValidLength: false,
    isValidWidth: false,
    isValidHeight: false,
    isValidWeight: false,
    isValidQty: false,
    isValidEditTime: false
  };
  const orderChange = {
    isChangeLength: false,
    isChangeWidth: false,
    isChangeHeight: false,
    isChangeWeight: false,
    isChangeQty: false,
    isChangeEditTime: false
  };
  //console.log('staff page upload: ', order_id, query)

  const [awb_validation, setAwbValidation] = useState(true);
  const baseUrl = `https://iacss.smtglobal.com.hk:3001`

  const getsonumber = async (get_company, sonumber) => {
    console.log(get_company);
    console.log(sonumber);
    setOrder([]); 
    setCheckValidation(false);
    setIsSearch(true);
    try {
      const slength = sonumber.length;
      const ulength = get_company.length;
      //console.log(get_company);

      if (slength > 0 && ulength > 0) {
          console.log("multi-edit search:" + { get_company } + { sonumber })
          const response1 = await fetch(baseUrl + `/api/orderlist/staffitem/${get_company}/${sonumber}`);
          const jsonData1 = await response1.json();
          const jsonData2 = await jsonData1.map(obj => ({ ...obj, checkbox_record: obj.move_out?1:-1 , companyname: get_company, validation, orderChange }))
          var orders = jsonData2.filter(data => data.data_source != "SOCOMP")
          setOrder(orders);
          setawb(jsonData1[0].awb);
      }
      setIsSearch(false);
      console.log(orderlist);
    } catch (err) {
      setawb("");
      console.error(err.message);
    }
  };

  const reset = async () => {
    setSearch("");
    setCompany("");
    setawb("");
    setRemark("");
    setIsSearch(false);
    setShow(false);
    setCheckValidation(false);
    setOrder([]);
    setAdditionalItem(0);
    setIsShowSearchMessage(false);
  }

  const searchAlert = (get_company, sonumber) => {
    const slength = sonumber.length;
    const ulength = get_company.length;
    if (slength <= 0 || ulength <= 0) {
      alert("Please input SO number or select company! 請輸入SO number或公司名稱!");
    }
  }

  const AllMoveOut = (orderlist) => {
    var alllMoveOutStatus = allMoveOut * -1 ;
    setAllMoveOutStatus(alllMoveOutStatus);
    if (alllMoveOutStatus == 1) {
      orderlist.map((orderlist, index) => {
        if(orderlist.move_out && userAccount !="admin"){
         return;
        }
        const isValidLength = ((orderlist.length != null && orderlist.length.length > 0) && isNaN(orderlist.length) == false) ? true : false;
        const isValidHeight = ((orderlist.height != null && orderlist.height.length > 0) && isNaN(orderlist.height) == false) ? true : false;
        const isValidWidth = ((orderlist.width != null && orderlist.width.length > 0) && isNaN(orderlist.width) == false) ? true : false;
        const isValidWeight = ((orderlist.weight != null && orderlist.weight.length > 0) && isNaN(orderlist.weight) == false) ? true : false;
        const isValidQty = ((orderlist.qty != null && orderlist.qty.length > 0) && isNaN(orderlist.qty) == false) ? true : false;
        const isValidOrder = isValidLength && isValidHeight && isValidWidth && isValidWeight && isValidQty
        isValidOrder ? setOrder((currentOrder) => produce(currentOrder, v => { v[index].checkbox_record = 1 })) : setOrder((currentOrder) => produce(currentOrder, v => { v[index].checkbox_record = -1 }))
      })
    } else {
      orderlist.map((orderlist, index) => {

        if(orderlist.move_out && userAccount !="admin"){
          return;
         }
        setOrder((currentOrder) => produce(currentOrder, v => { v[index].checkbox_record = -1 }))
      })

    }
  }

  const fetchRemark = async () => {
    try {
      const response = await fetch(baseUrl + `/api/remark/${company}/${searchSonumber}`);
      const jsonData = await response.json();
      console.log(jsonData.remark)
      setRemark(jsonData.remark);
    } catch (err) {
      setRemark("");
      console.error(err.message);
    }
  };

  useEffect(() => {
    getsonumber(company, searchSonumber)
    setChange(false)
  }, [Change]);

  useEffect(() => {
    checkValid(orderlist)
  }, [isSearch]);

  useEffect(() => {
    console.log("red valid")
    //setCheckValidation(false)
    checkValid(orderlist)
    console.log(orderlist)
  }, [checkValidation]);

  
  const setCompanyDetail = (companyname) => {
    setCompany(companyname);
    console.log("test:"+companylist.filter(lists => lists.username == companyname));
    companylist.filter(lists => lists.username == companyname).map(list => {
        console.log(list.uid);
        setCompanyId(list.uid)
    });
  }


  const isReadyOnly = (orderList) => {
    var readyOnly = false;
    if(orderList.move_in_day > 48){
      readyOnly = true;
    }

    if(orderList.move_out){
      readyOnly = true;
    }
   
    return readyOnly;
  }

  const checkValid = (orders) => {

    orders.map((orderlist, index) => {
      if (orderlist.move_in_day < 48) {
        const isValidLength = ((orderlist.length != null && orderlist.length.length > 0) && isNaN(orderlist.length) == false) ? true : false;
        const isValidHeight = ((orderlist.height != null && orderlist.height.length > 0) && isNaN(orderlist.height) == false) ? true : false;
        const isValidWidth = ((orderlist.width != null && orderlist.width.length > 0) && isNaN(orderlist.width) == false) ? true : false;
        var isValidWeight = false;
        if (orderlist.checkbox_record == -1) {
          isValidWeight = (isNaN(orderlist.weight) == false) ? true : false;
        } else {
          isValidWeight = ((orderlist.weight != null && orderlist.height.weight > 0) && isNaN(orderlist.weight) == false) ? true : false;
        }
       
        const isValidQty = ((orderlist.qty != null && orderlist.qty.length > 0) && isNaN(orderlist.qty) == false) ? true : false;
        const isValidOrder = isValidLength && isValidHeight && isValidWidth && isValidWeight && isValidQty
        console.log(isValidLength)
        //isValidOrder?setValidOrder(true):setValidOrder(false)

        setOrder((currentOrder) => produce(currentOrder, v => { v[index].validation.isValidLength = isValidLength }))
        setOrder((currentOrder) => produce(currentOrder, v => { v[index].validation.isValidHeight = isValidHeight }))
        setOrder((currentOrder) => produce(currentOrder, v => { v[index].validation.isValidWidth = isValidWidth }))
        setOrder((currentOrder) => produce(currentOrder, v => { v[index].validation.isValidWeight = isValidWeight }))
        setOrder((currentOrder) => produce(currentOrder, v => { v[index].validation.isValidQty = isValidQty }))
      }
    })

    if (awb && awb.length < 11 || isNaN(awb)) {
      setAwbValidation(false);
    } else {
      setAwbValidation(true);
    }


  }

  return (
    <div>
      <div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </div>

      <br></br>
      <h3>Multi-edit SO number 多項編輯</h3> <br />
      <Row >
        <Col xs="auto">
          <Form.Label >SO number:</Form.Label>
        </Col>

        <Col xs="auto">
          <Form.Control
            type="text"
            name="search"
            placeholder="SO number"
            className="form-control"
            value={searchSonumber.toUpperCase()}
            onChange={e => setSearch(e.target.value)}
            readOnly={isShowSearchMessage}
          />
        </Col>


        <Col xs="auto">
          <Form.Label>Company name 公司名稱:</Form.Label>
        </Col>

        <Col xs="auto">
          <Form.Control
            name="company"
            value={company}
            className="form-control"
            onChange={!isShowSearchMessage ? e => {
              setCompanyDetail(e.target.value)
            } : null}
            as="select"
          >
            <option value=""> -- Select an option 選擇 --</option>

            {companylist.map(company => (
              <option key={company.username} value={company.username}>{company.display_name}</option>

            ))}
          </Form.Control>
        </Col>
        <Col xs="auto">
          <button onClick={() => { getsonumber(company, searchSonumber); searchAlert(company, searchSonumber); fetchRemark(); setIsShowSearchMessage(true); }} className="btn btn-primary ml-10 float-left">
            Search 搜尋
          </button>
        </Col>

        <Col xs="auto">
          <button onClick={() => reset()} className="btn btn-dark ml-3">
            Reset 重置
          </button>
      </Col>
      </Row>

      <br></br>
      <Row xs="auto">


        {
          isShowSearchMessage ?
            orderlist.length > 0 ?
              <Col xs="auto">
                <Form.Label style={{ background: 'yellow' }} >SO number existing <br></br> 已存在SO number</Form.Label>
              </Col> :
              <Col xs="auto">
                <Form.Label style={{ background: 'red' }} >Do not have this SO number information <br></br> 沒有此SO number資料</Form.Label>
              </Col>
            : null
        }
      </Row >


      <br></br>


      <Row >
        <Col xs="auto">
          <label for="inputAWB">AWB:</label> &nbsp;
        </Col>
        {!awb_validation && checkValidation ? <label style={{ color: 'red' }}>(Invalid AWB , Must be a 11-digit number)</label> : null}
        <Col xs="auto">
          <input
            type="text"
            className="form-control"
            id="inputAWB"
            maxlength="11"
            value={awb}
            onChange={e => setawb(e.target.value)}
          />
        </Col>
      </Row>

      <br></br>

      <Row >
        <Col xs="auto">
          <label for="inputRemark">Remarks 備註:</label> &nbsp;
        </Col>
        <Col>
          <input
            type="text"
            className="form-control col-md-12"
            id="inputRemark"
            maxlength="180"
            value={remark}
            onChange={e => setRemark(e.target.value)}
          />
        </Col>
      </Row>

      <br></br>
      {orderlist.length > 1 ? <button onClick={() => AllMoveOut(orderlist)} className="btn btn-primary ml-10 float-right">
        All Move out 全部出倉
      </button> : null
      }
      <br></br>
      <br></br>



      {screenType.isDesktop || screenType.isLargeDesktop ? orderlist.map((orderlist, index) => (
        // window screen
        <div>
          <Row >
            <Col xs="auto">
              <Form.Label>L:</Form.Label>
            </Col>
            <Col xs={1}>
              <input
                type="text"
                style={!orderlist.validation.isValidLength && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                className="form-control col-md-12"
                id="inputL"
                maxlength="9"
                value={orderlist.length}
                onChange={(e) => { const length = e.target.value; const currentlength = orderlist.length; setOrder((currentOrder) => produce(currentOrder, v => { v[index].length = length; length == currentlength ? v[index].isDataChange *= 1 : v[index].isDataChange *= -1 })) }}
                readOnly={isReadyOnly(orderlist)}
              />
            </Col>

            <Col xs="auto">
              <Form.Label>W:</Form.Label>
            </Col>
            <Col xs={1}>
              <input
                type="text"
                style={!orderlist.validation.isValidWidth && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                className="form-control col-md-12"
                id="inputW"
                maxlength="9"
                value={orderlist.width}
                onChange={(e) => { const width = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].width = width })) }}
                readOnly={isReadyOnly(orderlist)}
              />
            </Col>


            <Col xs="auto">
              <Form.Label>H:</Form.Label>
            </Col>
            <Col xs={1}>
              <input
                type="text"
                style={!orderlist.validation.isValidHeight && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                className="form-control col-md-12"
                id="inputH"
                maxlength="9"
                value={orderlist.height}
                onChange={(e) => { const height = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].height = height })) }}
                readOnly={isReadyOnly(orderlist)}
              />
            </Col>


            <Col xs="auto">
              <Form.Label>QTY:</Form.Label>
            </Col>
            <Col xs={1}>
              <input
                type="text"
                style={!orderlist.validation.isValidQty && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                className="form-control col-md-12"
                id="inputQTY"
                maxlength="9"
                value={orderlist.qty}
                onChange={(e) => { const qty = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].qty = qty })) }}
                readOnly={isReadyOnly(orderlist)}
              />
            </Col>

            <Col xs="auto">
              <Form.Label>Weight:</Form.Label>
            </Col>
            <Col xs={1}>
              <input
                type="text"
                style={!orderlist.validation.isValidWeight && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                className="form-control col-md-12"
                id="inputWeight"
                maxlength="9"
                value={orderlist.weight !=null? orderlist.weight : null}
                onChange={(e) => { const weight = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].weight = weight })) }}
                readOnly={isReadyOnly(orderlist)}
              />
            </Col>

            <Col xs="auto">
              <Form.Label>Edit time:</Form.Label>
            </Col>
            <Col xs="auto">
              <input
                type="text"
                className="form-control col-md-10"
                id="inputEditTime"
                maxlength="9"
                value={orderlist.last_update_time}
              //  onChange={(e) => { const move_in = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].move_in = move_in })) }}
                readOnly={true}
              />
            </Col>


            <Col xs="auto">
              <Form.Label>Move out</Form.Label>
            </Col>

            <Col xs="auto">
              <InputGroup.Checkbox aria-label="Checkbox for following text input"
                checked={(orderlist.checkbox_record == 1) ? true : false}
                onChange={(e) => { const checkbox_record = -1; setOrder((currentOrder) => produce(currentOrder, v => { v[index].checkbox_record *= checkbox_record })) }}
                disabled={(orderlist.move_out && userAccount != "admin") ? true : false}
              />

            </Col>
          </Row>
        </div>

      )) :
        // phone screen
        orderlist.map((orderlist, index) => (
          <div key={orderlist.id}>
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm={1} xs={1}>L:</Form.Label>
                <Col sm={5} xs={5}>
                  <input
                    type="text"
                    style={!orderlist.validation.isValidLength && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                    className="form-control col-xs-12"
                    id="inputL"
                    maxlength="9"
                    value={orderlist.length}
                    onChange={(e) => { const length = e.target.value; const currentlength = orderlist.length; setOrder((currentOrder) => produce(currentOrder, v => { v[index].length = length; length == currentlength ? v[index].isDataChange *= 1 : v[index].isDataChange *= -1 })) }}
                    readOnly={isReadyOnly(orderlist)}
                  />
                </Col>

                <Form.Label column sm={1} xs={1}>W:</Form.Label>
                <Col sm={5} xs={5}>
                  <input
                    type="text"
                    style={!orderlist.validation.isValidWidth && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                    className="form-control "
                    id="inputW"
                    maxlength="9"
                    value={orderlist.width}
                    onChange={(e) => { const width = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].width = width })) }}
                    readOnly={isReadyOnly(orderlist)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>

                <Form.Label column sm={2} xs={2}>H:</Form.Label>
                <Col sm={4} xs={4}>
                  <input
                    type="text"
                    style={!orderlist.validation.isValidHeight && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                    className="form-control col-md-12"
                    id="inputW"
                    maxlength="9"
                    value={orderlist.height}
                    onChange={(e) => { const height = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].height = height })) }}
                    readOnly={isReadyOnly(orderlist)}
                  />
                </Col>

                <Form.Label column sm={2} xs={2}>QTY:</Form.Label>
                <Col sm={4} xs={4}>
                  <input
                    type="text"
                    style={!orderlist.validation.isValidQty && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                    className="form-control col-md-12"
                    id="inputQTY"
                    maxlength="9"
                    value={orderlist.qty}
                    onChange={(e) => { const qty = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].qty = qty })) }}
                    readOnly={isReadyOnly(orderlist)}
                  />
                </Col>

              </Form.Group>


              <Form.Group as={Row}>

                <Form.Label column sm={3} xs={3}>Weight:</Form.Label>
                <Col sm={5} xs={5}>
                  <input
                    type="text"
                    style={!orderlist.validation.isValidWeight && orderlist.move_in_day < 48 && checkValidation ? { background: 'red' } : null}
                    className="form-control col-md-12"
                    id="input_weight"
                    maxlength="9"
                    value={orderlist.weight != null? orderlist.weight : null}
                    onChange={(e) => { const weight = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].weight = weight })) }}
                    readOnly={isReadyOnly(orderlist)}
                  />
                </Col>

                <Form.Label column sm={2} xs={2}>Move out</Form.Label>
                <Col sm={2} xs={2}>
                  <InputGroup.Checkbox aria-label="Checkbox for following text input"
                    checked={orderlist.checkbox_record == 1 ? true : false}
                    onChange={(e) => { const checkbox_record = -1; setOrder((currentOrder) => produce(currentOrder, v => { v[index].checkbox_record *= checkbox_record })) }}
                    disabled={(orderlist.move_out && userAccount != "admin") ? true : false}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="auto" xs="auto">Edit time:</Form.Label>
                <Col sm={8} xs={8}>
                  <input
                    type="text"
                    className="form-control col-md-12"
                    id="input_move_in"
                    maxlength="9"
                    value={orderlist.last_update_time}
                   // onChange={(e) => { const move_in = e.target.value; setOrder((currentOrder) => produce(currentOrder, v => { v[index].move_in = move_in })) }}
                    readOnly={true}
                  />
                </Col>
              </Form.Group>
            </Form>
===========================
        </div>

      ))}

      <br></br>
      <br></br>
      <Row >
        <Col xs="auto">
          <label for="inputItem">Additional new item 新增項數:</label> &nbsp;
        </Col>
        <Col xs="auto">
          <input
            type="text"
            className="form-control"
            id="inputItem"
            maxlength="9"
            value={additionalItem}
            onChange={e => setAdditionalItem(e.target.value)}
          />
        </Col>

  x
      </Row>

      <br></br>





      <Row>

        <p align="right">
          {/*<Button variant="primary" onClick={handleShow}>
        Check 檢查
       </Button>*/
          }
          <MutiEditConfirmPop orderlist={orderlist} change={setChange}  resetOrder={setOrder} getsonumber={getsonumber} popshow={show} remark={remark} validation={setCheckValidation} awb={awb} checkValid ={checkValid} companyId={companyId} />

          <Link to="/staff/orderlist">
            <button type="button" className="btn btn-dark ml-3">
              Close 關閉
            </button>
          </Link>
        </p>

      </Row>

    </div>


  );
};

export default MutiEditPage;
