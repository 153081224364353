import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";


const LoginImage = ({setAuth2,setUser,setColor}) => {
  const [inputs, setLogin] = useState({
    username: "",
    password: ""
  });
  var { username, password } = inputs;

  const onChange = e =>
    setLogin({ ...inputs, [e.target.name]: e.target.value });

  const init = e => {
    setUser("operator");
    setColor('#f7d8ab');
  }
  
    

  const onSubmitForm = async(e) => {
    e.preventDefault();
    try {
      username = username.split(' ').join('');
      const body = { username, password };
      const response = await fetch(
        "https://iacss.smtglobal.com.hk:3001/api/auth/login/operator",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json"
          },
          body: JSON.stringify(body)
        }
      );
      const parseRes = await response.json();
      
      if (parseRes.jwtToken){
        //console.log("1");
        localStorage.setItem("userAccount", "operator");
        localStorage.setItem("token", parseRes.jwtToken);
        setAuth2(true);
        setUser("operator")
      }else{
        //console.log("2");
        setAuth2(false);
        setUser("operator")
        alert("Wrong username or password! 登入帳戶或密碼錯誤!"); 
      }
    
    

    } catch (err) {
      console.error(JSON.stringify(err.message));
    }
  }; 

  useEffect(() => {
    init()
  }, []);

  return (
      <Fragment>
        <div className="jumbotron mt-5">
        <h1 className="text-center mt-5">Inspection Login Page</h1>

          <form onSubmit={onSubmitForm}>
          <label>Username 登入帳戶</label>
          <br></br>
          <input
          type="text" 
          name="username"
          placeholder = "username"
          className="form-control mt-3"
          value={username}
          onChange={e => onChange(e)}
          />
          <br></br>
          <label>Password 密碼</label>
          <br></br>
          <input
          type="password" 
          name="password"
          placeholder = "password"
          className="form-control mt-3"
          value={password}
          onChange={e => onChange(e)}
          />
          <br></br>
          <br></br>
          <button className="btn btn-success btn-block">Login 登入</button>
          <Link to="/home" className="btn btn-success btn-block" onClick={() => {document.documentElement.style.setProperty('--root_colour', 'white');document.documentElement.style.setProperty('--root_div_colour', 'white')}}>Cancel 取消</Link>
          <br></br>
         
          </form>
          </div>
        
        
        
      </Fragment>
    );



};
export default LoginImage;
