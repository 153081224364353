//upload x-ray image to AWS S.3
import { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation
} from "react-router-dom";
import { Table, Button, Row, Col } from "react-bootstrap";

const OperatorPalletMain = () => {

  const { query } = useLocation();
  const [sonumber, setDescription] = useState("")
  const [userType, setUserType] = useState("")
  const [orderlist, setOrderList] = useState([])
  const [bulkRecordList, setBulkRecordList] = useState([])
  const [companyname, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [change, setChange] = useState(false)
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);


  const baseUrl = `https://iacss.smtglobal.com.hk:3001`

  const init = async () => {
    if (query != null || typeof (query) !== "undefined") {
      setDescription(query.sonumber)
      setCompanyDisplayName(query.companyDisplayName)
      setCompanyName(query.companyName)
      setOrderList(query.orderlist)
      setUserType(query.userType)
    } else {
      window.location.reload();
    }
  }

  function insertBulkRecord() {
    initIacssBulkRecord(companyname, sonumber)
  }

  const getBulkRecord = async () => {
    try {
      setLoading(true)
      console.log(loading);
      const res = await fetch(baseUrl + `/api/iacss/get_bulk_record/${companyname}/${sonumber}`)
      const jsonData = await res.json();
      console.log(jsonData);
      setBulkRecordList(jsonData);
      setLoading(false)
      console.log(loading);
    } catch (err) {
      setLoading(false)
      console.error(err.message);
    }
  };

  const initIacssBulkRecord = async (companyname, sonumber) => {
    try {
      setLoading(true)
      if (companyname == null || sonumber == null) return
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("jwt_token", localStorage.token);
      const iacssBody = { sonumber, companyname };
      const iacssResponse = await fetch(baseUrl + "/api/iacss/create_bulk_record", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(iacssBody)
      }).then((response) => { if (response.status == 500) { setHasError(true) } });
      setLoading(false)
      setChange(!change)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    init()
  }, [query]);

  useEffect(() => {
    getBulkRecord()
  }, [change]);

  useEffect(() => {
    getBulkRecord()
  }, [sonumber, companyname]);

  useEffect(() => {
    if (hasError == true) {
      alert("資料儲取失敗,請重新嘗試")
      setHasError(false)
    }
  }, [hasError]);


  return (

    <div>
      <br></br>
      <p align="right">
        {userType == "operator" ?
          <Link to="/operator/operatorPage">
            <button type="button" className="btn btn-dark ml-3">
              Back to Operator Insert Page <br></br>返回操作員輸入頁面
            </button>
          </Link> :

          <Link to="/staff/orderlist">
            <button type="button" className="btn btn-dark ml-3">
              Back to Order List 返回訂單表
            </button>
          </Link>
        }
      </p>
      <Row>
        <h3 style={{ fontWeight: 'bold' }}> SO pallet_ID Index 貨板目錄
        </h3><br></br><br></br>
        <h3>SO number: {sonumber}</h3><br></br><br></br>
        <h3>Company 公司: {companyDisplayName}</h3> <br></br><br></br>
        <Col xs="auto" sm="auto">
          <h3>SO pallet_ID list 列表:</h3>
        </Col>
        <Col xs="auto" sm="auto">
          <Button variant="danger" onClick={insertBulkRecord} disabled={loading}>
            Add 增加
          </Button>
        </Col>
        <br></br>
        <br></br>
        <Table class="table table-hover table-light" responsive style={{ width: '50%'}}> 
          <thead>
            <tr align="center">
              <th>序號 id</th>
              <th>重量 weight(kg)</th>
            </tr>
          </thead>
          <tbody align="center">
            {bulkRecordList.map(recordlist => (
              <tr align="center">
                <td>
                  <Link to={{ pathname: `/operator/operatorImageAndSizeMain/`, query: { sonumber: sonumber, bulkRecordUid: recordlist.uid, increment: recordlist.increment, weight: recordlist.weight, companyDisplayName: companyDisplayName, companyName: companyname, userType: "operator" } }}>
                    <Button variant="link">
                      {recordlist.so_number + "-" + recordlist.increment}
                    </Button>
                  </Link>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {recordlist.weight}
                </td>
              </tr>

            ))}
          </tbody>
        </Table>
      </Row>
    </div >
  );
};

export default OperatorPalletMain;
