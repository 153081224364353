import React, { Fragment, useState, useEffect } from "react";
import { produce } from "immer";
import { InputGroup, Container, Row, Col, Modal, Button } from "react-bootstrap";
import {
  Link, useLocation
} from "react-router-dom";

const OperatorModifyConfirmPop = ({ sonumber, company, companyDisplayName, companyId }) => {


  const baseUrl = `https://iacss.smtglobal.com.hk:3001`
  const [show, setShow] = useState();
  const [orderlist, setOrder] = useState([]);
  var regEx = /^(?!\s)[A-Z0-9\s]+$/i;


  const handleClose = () => {

    setShow(false);
  }
  const handleShow = () => {
    if (regEx.test(sonumber) === false) {
      alert("Please enter correct SO number." + "\n請輸入適當的SO number。")
      return
    }

    if (company == "") {
      console.log(company);
      alert("Please choose Company." + "\n請選擇適當的公司。")
      return
    }

    confirm();
    setShow(true);
  }

  const confirm = async () => {
    try {
      const response1 = await fetch(baseUrl + `/api/iacss/sales_order/${company}/${sonumber}`);
      const jsonData1 = await response1.json();

      if (jsonData1.length > 0) {
        console.log("order find:" + jsonData1)
        setOrder(jsonData1);
      }
    } catch (e) {
      console.error(e)
    }
  }

  const addOrder = async (companyname, companyId, sonumber) => {
    try {
      if (orderlist.length <= 0) {
        console.log("add Order")
        await initOrder(companyname, companyId, sonumber)
        await initIacssOrder(companyname, sonumber)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const initOrder = async (companyname, companyId, sonumber) => {
    console.log("Creat new order")
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("jwt_token", localStorage.token);
    const body = { sonumber, companyname, companyId };


    const response = await fetch("https://iacss.smtglobal.com.hk:3001/api/orderlist", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),

    });
  }



  const initIacssOrder = async (companyname, sonumber) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("jwt_token", localStorage.token);
      const iacssBody = { sonumber, companyname };
      const iacssResponse = await fetch("https://iacss.smtglobal.com.hk:3001/api/so_samples", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(iacssBody),

      });

      console.log(iacssResponse)
    } catch (e) {
      console.error(e)
    }
  }


  return (
    <Fragment>

      <Button variant="primary" onClick={handleShow}>
        modify修改
      </Button>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm  確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {(orderlist && !(orderlist.length > 0)) ?
            <div> No related {sonumber} with {company} exist. Sure to enter?
            </div> : null
          }


          {(orderlist && orderlist.length > 0) ?
            <div> {sonumber} with {company} already exist. Sure to enter?
            </div> : null
          }

          {(orderlist && !(orderlist.length > 0)) ?
            <div> {company}中沒有{sonumber}，確認輸入?
            </div> : null
          }

          {(orderlist && orderlist.length > 0) ?
            <div> {company}中已有{sonumber}，確認輸入?
            </div> : null
          }




        </Modal.Body>
        <Modal.Footer>
          <Link to={{ pathname: `/operator/operatorPalletMain`, query: { sonumber: sonumber, companyDisplayName: companyDisplayName, companyId: companyId, companyName: company, orderlist: orderlist, userType: "operator" } }}>
            <Button variant="primary" data-dismiss="modal" onClick={() => addOrder(company, companyId, sonumber)}>
              Confirm 確定
            </Button>
          </Link>

          <Button variant="secondary" onClick={handleClose}>
            Cancel 取消
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default OperatorModifyConfirmPop;
