import './CopyrightFooter.css';
export default function CopyrightFooter(){
    return (
      <footer id="copyright-footer">
       <p>
        {
           `Copyright © 2022${new Date().getFullYear()>2022?`-${new Date().getFullYear()}`:""} SMT Global Logistics Limited. All rights reserved.`
          }
        </p>
        <p>
          {
            `版權所有 © 2022${new Date().getFullYear()>2022?`-${new Date().getFullYear()}`:""} 全球通航運物流有限公司`
          }
        </p>
      </footer>
    )
  }