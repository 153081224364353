import React, { Fragment, useState } from "react";
import {InputGroup, Container, Row, Col , Modal , Button} from "react-bootstrap";

const EditConfirmPop = ( {confirmSource , orderlist , setChange , checkbox_record , editShow, sonumber, qty, awb, length, weight, height, width, companyname, companyId,clean_move_out, awbValidation, length_validation, width_validation, height_validation, qty_validation, weight_validation, from_client_page , remark}) => {
    const baseUrl = `https://iacss.smtglobal.com.hk:3001`

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () =>{
     //validation
     if (awb && awb.length < 11 || isNaN(awb)) {
      awbValidation(false);
      return
    } else {
      awbValidation(true);
    }

    console.log('from_client_page: ', from_client_page);

    //As client page edit press 'update' will also forward to here to do validation
    if (from_client_page == null) {
      console.log('check val length: ', length, isNaN(length));

      if (length != null && length.length < 1 || isNaN(length)) {
        length_validation(false);
        return
      } else {
        length_validation(true);
      }

      console.log('check val width: ', width, isNaN(width));

      if (width != null && width.length < 1 || isNaN(width)) {
        width_validation(false);
        return
      } else {
        width_validation(true);
      }

      console.log('check val height: ', height, isNaN(height));

      if (height != null && height.length < 1 || isNaN(height)) {
        height_validation(false);
        return
      } else {
        height_validation(true);
      }

      if (qty != null && isNaN(qty)) {
        qty_validation(false);
        return
      } else {
        qty_validation(true);
      }

      if (weight != null && isNaN(weight)) {
        weight_validation(false);
        return
      } else {
        weight_validation(true);
      }
    }
    setShow(true);
  }

  const validation = () => {
    //validation
    if (awb && awb.length < 9 || isNaN(awb)) {
      awbValidation(false);
      return
    } else {
      awbValidation(true);
    }

    console.log('from_client_page: ', from_client_page);

    //As client page edit press 'update' will also forward to here to do validation
    if (from_client_page == null) {
      console.log('check val length: ', length, isNaN(length));

      if (length != null && length.length < 1 || isNaN(length)) {
        length_validation(false);
        return
      } else {
        length_validation(true);
      }

      console.log('check val width: ', width, isNaN(width));

      if (width != null && width.length < 1 || isNaN(width)) {
        width_validation(false);
        return
      } else {
        width_validation(true);
      }

      console.log('check val height: ', height, isNaN(height));

      if (height != null && height.length < 1 || isNaN(height)) {
        height_validation(false);
        return
      } else {
        height_validation(true);
      }

      if (qty != null && isNaN(qty)) {
        qty_validation(false);
        return
      } else {
        qty_validation(true);
      }

      if (weight != null && isNaN(weight)) {
        weight_validation(false);
        return
      } else {
        weight_validation(true);
      }
    }
  }



  const handleEditClose = () => editShow(false);


    const confirmUpdate = (e, confirmSource)=>{

        if(confirmSource == "orderList"){
         updateorderlist(e ,sonumber, qty, awb, length, weight, height, width, companyname,companyId, clean_move_out);
        }else if(confirmSource == "client"){
        updateClientOrderlist(e, awb, companyname);
        }

        submitRemark(e,sonumber ,companyname,remark);
    }

    const updateorderlist = async (e,sonumber, qty, awb, length, weight, height, width, companyname, companyId ,clean_move_out) => {
        console.log('update1')
        console.log('orderlist'+orderlist.sonumber)
        console.log('setChange'+setChange)
        console.log('checkbox_record'+sonumber)
        e.preventDefault();
        try {
          if (checkbox_record == false) {
            console.log('update2');
            const body = { sonumber, qty, awb, length, weight, height, width, companyname, companyId ,clean_move_out };
            const response = await fetch(
              baseUrl+`/api/orderlist/${orderlist.order_id}`,
              {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
              }
            );

            setChange(true);
            }else if (checkbox_record == true) {
                console.log('update3')
            const body = { sonumber, qty, awb, length, weight, height, width, companyname };
            const response = await fetch(
              baseUrl + `/api/orderlist/move_out/${orderlist.order_id}`,
              {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
              }
            );
            setChange(true);
          }
         handleClose();
         handleEditClose();
        } catch (err) {
          console.error(err.message);
        }
      };



      const updateClientOrderlist = async (e, awb, companyname) => {
        e.preventDefault();
        try {
            console.log('update2')
            const body = {awb, companyname};

            const orderResponse = await fetch(baseUrl+`/api/orderlist/${companyname}/${orderlist.sonumber}`);
            const jsonData = await orderResponse.json();
            console.log(jsonData)
            jsonData.filter(orderlist =>orderlist.order_id !=null).map(orderlist => {
              submitAwb(e, awb, companyname ,orderlist.order_id);
            })



            setChange(true);


         handleClose();
         handleEditClose();
        } catch (err) {
          console.error(err.message);
        }
      };

      const submitAwb = async (e,awb, companyname, order_id) => {
        const body = {awb, companyname};
        const response = await fetch(
          baseUrl+`/api/orderlist/sonumber/${order_id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
          }
        );
      }

      const submitRemark = async (e,sonumber, companyname, remark) => {
        e.preventDefault();
        try{

          const response = await fetch(baseUrl+`/api/remark/${companyname}/${sonumber}`);
          const jsonData = await response.json();
          if(jsonData && (jsonData.remark != "" || jsonData.remark != null)){

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("jwt_token", localStorage.token);
            const body = {remark};
            console.log(remark)
            if(sonumber=="" || companyname==""){
                console.log("Fail to insert invalid data")
            }else{
                const response = await fetch(baseUrl+`/api/remark/update/${companyname}/${sonumber}`, {
                   method: "PUT",
                   headers: myHeaders,
                   body: JSON.stringify(body),

                });
                const parseResponse = await response.json();
                console.log(parseResponse);
            }
          }else{

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("jwt_token", localStorage.token);
            const body = { sonumber , companyname, remark};
            if(sonumber=="" || companyname=="" ||remark==""){
                console.log("Fail to insert invalid data")
            }else{
                const response = await fetch(baseUrl+`/api/remark`, {
                   method: "POST",
                   headers: myHeaders,
                   body: JSON.stringify(body),

                });
                const parseResponse = await response.json();
                console.log(parseResponse);
            }

          }
        } catch (err){
            console.error(err.message);
        }
    };



return (
<Fragment>

    <Button variant="primary" onClick={handleShow}>
        Update 更新
    </Button>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm edit 確認編輯</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div> Confirm to update {sonumber}.
        </div>
        <div>確認更新 {sonumber}。
        </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary"  onClick={e => confirmUpdate(e, confirmSource)} data-dismiss="modal">
            Confirm 確定
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel 取消
          </Button>
        </Modal.Footer>
      </Modal>
</Fragment>
)
};

export default EditConfirmPop;
