import React, { Component, Fragment, useEffect, useState } from "react";
import { Container, Col, Row, backgroundImage, FormGroup } from "react-bootstrap";
import smtlogo from './SMTlogo.png'
import stllogo from './STLlogo.png'
import bgImage from './bg3.png';
//import './home.css';


const home = () => {
  
  const styles = {

        backgroundImage: `url(${bgImage})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '80vw',
        height: '100vh',

};


  return (
    <div> 
   <div style={styles} align= "center" > 
    <div className="jumbotron mt-3" align="center">
      <Row>
        <Col xs="auto"><img src={stllogo} width="80" height="80" alt="logo"/></Col>
        <Col xs="auto">
          <h1>Welcome to SMT Webapp</h1> 
          <h1>歡迎到 SMT 網路應用程式</h1>
        </Col>
        <Col xs="auto"><img src={smtlogo} width="80" height="80" alt="logo"/></Col>
      </Row>    
    </div>

    </div>
    </div>
  );
};
export default home;