import React, { Fragment, useState } from "react";
import { InputGroup, Container, Row, Col, Modal, Button, NavItem ,Form} from "react-bootstrap";
import EditConfirmPop from "./editConfirmPop";


const Edit = ({ confirmSource, orderlist,companyList ,setChange, userPermission, userAccount }) => {
  const [sonumber, setsonumber] = useState(orderlist.sonumber);
  const [qty, setqty] = useState(orderlist.qty);
  const [weight, setweight] = useState(orderlist.weight);
  const [height, setheight] = useState(orderlist.height);
  const [length, setlength] = useState(orderlist.length);
  const [width, setwidth] = useState(orderlist.width);
  const [awb, setawb] = useState(orderlist.awb);
  const [remark, setRemark] = useState([]);
  const [companyname, setcompanyname] = useState(orderlist.companyname);
  const [companyId, setCompanyId] = useState(0);
  const [companylist, setcompanylist] = useState(companyList);
  const [data_source, setData_source] = useState(orderlist.data_source);
  const [images, setImages] = useState([]);
  //checkbox
  const [checkbox_item, setCheckbox_item] = useState(0);
  const [checkbox_status, setCheckbox_status] = useState({});
  const [checkbox_record, setCheckbox_record] = useState('');
  const [checkbox_init, setCheckbox_init] = useState(() => get_checkbox_init());
  const [clean_move_out, setClean_move_out] = useState(null);

  const [show, setShow] = useState(false);
  const [awb_validation, setAwbValidation] = useState(true);
  const [length_validation, setLengthValidation] = useState(true);
  const [width_validation, setWidthValidation] = useState(true);
  const [height_validation, setHeightValidation] = useState(true);
  const [qty_validation, setQtyValidation] = useState(true);
  const [weight_validation, setWeightValidation] = useState(true);

  const handleShow = () => {
    setShow(true);
    fetchRemark();
    if (orderlist.move_in_day > 48) alert(`(${orderlist.sonumber})` + ` stored over 48 hours and can only edit "Remarks" and "Move out". 此貨物已存倉超過48小時只能編輯"備註"及"出倉"。`)
  }
  const handleClose = () => {
    setAwbValidation(true);
    setLengthValidation(true);
    setWidthValidation(true);
    setHeightValidation(true);
    setQtyValidation(true);
    setWeightValidation(true);
    setShow(false)
  };

  const baseUrl = `https://iacss.smtglobal.com.hk:3001`

  //To see is the SO number has moved out
  function get_checkbox_init() {
    if (orderlist.move_out == null) {
      return false;
    } else if (orderlist.move_out != null) {
      return true;
    }
  }

  const setCompanyDetail = (companyname) => {
    setcompanyname(companyname);
    console.log("test:"+companylist.filter(lists => lists.username == companyname));
    companylist.filter(lists => lists.username == companyname).map(list => {
        console.log(list.uid);
        setCompanyId(list.uid)
    });
  }


  function is_readyOnly(editField) {

    var is_readyOnly = false;

    if (orderlist.move_in_day > 48) {
      is_readyOnly = true;
    }

    if ((editField == "sonumber" || editField == "companyName") && (userAccount == "staff" ||  typeof (userAccount) == "undefined" || userAccount =="")) {
      is_readyOnly = true;
    }

    if ((editField == "length" || editField == "height" || editField == "width" || editField == "moveIn") && data_source == "SOCOMP") {
      is_readyOnly = true;
    }

    if(editField == "AWB" || editField == "remark"){
      is_readyOnly = true;
    }

    return is_readyOnly;

  }

  const Checkbox = ({ type = "checkbox", name, checked = checkbox_init, onChange }) => {
    //console.log("Checkbox: ", name, checked);  
    setCheckbox_record(checked);
    //check if there is move out record, there will be not checkbox

    return (<input type={type} name={name} checked={checked} onChange={onChange} />);
  };

  const handleChange = event => {
    setCheckbox_status({
      ...checkbox_status,
      [event.target.name]: event.target.checked
    });
  };

  const checkboxes = [
    {
      name: "Move out 出倉 ",
      key: "move_out_checkbox",
      label: "Move out checkbox"
    }
  ];

  const fetchRemark = async () => {
    try {
      const response = await fetch(baseUrl + `/api/remark/${companyname}/${sonumber}`);
      const jsonData = await response.json();
      setRemark(jsonData.remark);
    } catch (err) {
      console.error(err.message);
    }
  };

  const imagename = async (sonumber) => {
    const response1 = await fetch(baseUrl + `/api/key/${sonumber}/${orderlist.order_id}`);
    const jsonData1 = await response1.json();
    //console.log(jsonData1)
    setImages(jsonData1);
  };

  const updateorderlsit = async e => {
    e.preventDefault();
    try {
      if (checkbox_record == false) {
        const body = { sonumber, qty, awb, length, weight, height, width, companyname, clean_move_out };
        const response = await fetch(
          baseUrl + `/api/orderlist/${orderlist.order_id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
          }
        );
        setChange(true);
      } else if (checkbox_record == true) {
        const body = { sonumber, qty, awb, length, weight, height, width, companyname };
        const response = await fetch(
          baseUrl + `/api/orderlist/move_out/${orderlist.order_id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
          }
        );
        setChange(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Fragment>

      <Button style={{height: '40px', width : '120px'}} disabled={orderlist.move_out != null ? true : false} variant="primary" onClick={handleShow}>
        Edit 編輯
      </Button>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit 編輯</Modal.Title>
        </Modal.Header>
        <Modal.Body style={
          (data_source == "SOCOMP")
            ? { background: '#EAF803' }
            : null}>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputSO">SO number</label>
              <input
                type="text"
                className="form-control"
                id="inputSO"
                value={sonumber.toUpperCase()}
                onChange={e => setsonumber(e.target.value)}
                readOnly={is_readyOnly("sonumber") ? true : false}
              />
            </div>
            <div class="form-group col-md-2">
              <label>Move in 入倉</label>
              <input
                className="form-control"
                value={orderlist.move_in}
                readOnly='True'></input>
            </div>
            <div class="form-group col-md-2">
              <label>Move out 出倉</label>
              <input
                className="form-control"
                value={orderlist.move_out}
                readOnly='True'></input>
            </div>

            <div class="form-group col-md-2">
              {/*<input name="move_out_check" type="checkbox" checked={check_box_status2}
                  onClick={() => { 
                    if (checkbox_item == 0) { setCheckbox_item(1); }
                    else if (checkbox_item == 1) { setCheckbox_item(0); }
                  }}
                />*/}
              {checkboxes.map(item => (
                <label key={item.key}>
                  {item.name}
                  <Checkbox
                    name={item.name}
                    checked={checkbox_status[item.name]}
                    onChange={handleChange}
                  />
                </label>
              ))}
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputAWB">AWB</label> &nbsp;
              {!awb_validation ? <label style={{ color: 'red' }}>(Invalid AWB , Must be a 11-digit number)</label> : null}
              <input
                type="text"
                className="form-control"
                id="inputAWB"
                maxlength="11"
                value={awb}
                onChange={e => setawb(e.target.value)}
                readOnly={true}
              />

            </div>
            {/** 
              <div class="form-group col-md-4">
              <label for="inputCompany">Company name 公司名稱</label>
              <input
                type="text"
                className="form-control"
                id = "inputCompany"
                maxlength="20"
                value={companyname}
                onChange={e => setcompanyname(e.target.value)}
                readOnly={is_readyOnly("companyName")? true:false}
              />
              </div>
              */}

            <Form.Group>
              <Form.Label>Select company 選擇公司:</Form.Label>
              <Form.Control
                name="company"
                value={companyname}
                className="form-control"
                disabled = {is_readyOnly("companyName") ? true : false}
                onChange={e => {
                  setCompanyDetail(e.target.value);
                }}
                as="select"
              >
              
                {companylist.map(company => (
                  <option key={company.username} value={company.username}>{company.display_name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>

          <div class="form-row">
            <div class="form-group col-md-2">
              <label for="inputL">L 長 (cm)</label> &nbsp;
              {!length_validation ? <label style={{ color: 'red' }}>(Invalid)</label> : null}
              <input
                type="text"
                className="form-control"
                id="inputL"
                maxlength="10"
                value={length}
                onChange={e => setlength(e.target.value)}
                readOnly="true"
               // readOnly={is_readyOnly("length") ? true : false}
              />
            </div>
            <div class="form-group col-md-2">
              <label for="inputW">W 寬 (cm)</label> &nbsp;
              {!width_validation ? <label style={{ color: 'red' }}>(Invalid)</label> : null}
              <input
                type="text"
                className="form-control"
                id="inputW"
                maxlength="10"
                value={width}
                onChange={e => setwidth(e.target.value)}
                readOnly="true"
                //readOnly={is_readyOnly("width") ? true : false}
              />
            </div>
            <div class="form-group col-md-2">
              <label for="inputH">H 高 (cm)</label> &nbsp;
              {!height_validation ? <label style={{ color: 'red' }}>(Invalid)</label> : null}
              <input
                type="text"
                className="form-control"
                id="inputH"
                maxlength="10"
                value={height}
                onChange={e => setheight(e.target.value)}
                readOnly="true"
                //readOnly={is_readyOnly("height") ? true : false}
              />
            </div>
            <div class="form-group col-md-2">
              <label for="inputQTY">QTY 數量</label>
              {!qty_validation ? <label style={{ color: 'red' }}>(Invalid)</label> : null}
              <input
                type="text"
                className="form-control"
                id="inputQTY"
                maxlength="10"
                value={qty}
                onChange={e => setqty(e.target.value)}
                readOnly="true"
                //readOnly={is_readyOnly("qty") ? true : false}
              />
            </div>
            <div class="form-group col-md-2">
              <label for="inputWeight">WT 重量（kg）</label>
              {!weight_validation ? <label style={{ color: 'red' }}>(Invalid)</label> : null}
              <input
                type="text"
                className="form-control"
                id="inputWeight"
                maxlength="10"
                value={weight}
                onChange={e => setweight(e.target.value)}
                readOnly="true"
              //  readOnly={is_readyOnly("weight") ? true : false}
              />
            </div>
          </div>

          <div class="form-group col-md-10">
            <label for="inputWeight">Remarks 備註:</label>
            <input
              type="text"
              className="form-control"
              id="inputRemark"
              maxlength="180"
              value={remark}
              onChange={e => setRemark(e.target.value)}
              readOnly={true}
            />
          </div>


          {/* image displayer */}
          <Row>
            {images.map(image =>
            (
              <Col xs={3} md={3}>
                <div key={image}>
                  <img src={baseUrl + `/api/images/${image.key}`} width="300" height="300" />
                </div>
              </Col>
            ))}</Row>

        </Modal.Body>

        <Modal.Footer>
          <EditConfirmPop confirmSource={confirmSource} orderlist={orderlist} setChange={setChange} checkbox_record={checkbox_record} editShow={handleClose}
            sonumber={sonumber} qty={qty} awb={awb} length={length} weight={weight} height={height} width={width} companyname={companyname} companyId={companyId} clean_move_out={clean_move_out}
            awbValidation={setAwbValidation} length_validation={setLengthValidation} width_validation={setWidthValidation} height_validation={setHeightValidation} qty_validation={setQtyValidation} weight_validation={setWeightValidation} remark={remark} />


          <Button variant="secondary" onClick={handleClose}>
            Close 關閉
          </Button>

        </Modal.Footer>
      </Modal>


    </Fragment>
  );
};

export default Edit;
