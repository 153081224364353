import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";


const LoginStaff = ({ setAuth, setUser }) => {
  const baseUrl = `https://iacss.smtglobal.com.hk:3001`
  const [inputs, setLogin] = useState({
    username: "",
    password: ""
  });
  var { username, password } = inputs;

  const onChange = e =>
    setLogin({ ...inputs, [e.target.name]: e.target.value });

  const init = e =>
    setUser("staff");

  const onSubmitForm = async (e) => {
    e.preventDefault();
    try {
      username = username.split(' ').join('');
      const body = { username, password };

     

      const response = await fetch(
        "https://iacss.smtglobal.com.hk:3001/api/auth/login/staff",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json"
          },
          body: JSON.stringify(body)
        }
      );

      const parseRes = await response.json();
      if (parseRes.jwtToken) {
        localStorage.setItem("token", parseRes.jwtToken);

        const response1 = await fetch(baseUrl + `/api/user/permission/${username}`, {
          method: "GET",
          headers: { jwt_token: localStorage.token }
        });
        const parseData = await response1.json();
        if (parseData.length > 0) {
          localStorage.setItem("userAccount", parseData[0].user_account);
        }else{
          localStorage.setItem("userAccount", "staff");
        }

        setAuth(true);
      } else {
        setAuth(false);
        alert("Wrong username or password! 登入帳戶或密碼錯誤!");
      }


    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    init()
  }, []);

  return (
    <Fragment>
      <div className="jumbotron mt-5">
        <h1 className="text-center mt-5">Staff Login Page</h1>

        <form onSubmit={onSubmitForm}>
          <label>Username 登入帳戶</label>
          <br></br>
          <input
            type="text"
            name="username"
            placeholder="username"
            className="form-control mt-3"
            value={username}
            onChange={e => onChange(e)}
          />
          <br></br>
          <label>Password 密碼</label>
          <br></br>
          <input
            type="password"
            name="password"
            placeholder="password"
            className="form-control mt-3"
            value={password}
            onChange={e => onChange(e)}
          />
          <br></br>
          <br></br>
          <button className="btn btn-success btn-block">Login 登入</button>
          <Link to="/home" className="btn btn-success btn-block">Cancel 取消</Link>
          <br></br>

        </form>
      </div>



    </Fragment>
  );



};
export default LoginStaff;
