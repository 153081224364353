import React, { Fragment, useEffect, useState } from "react";
import { Table, Row, Col, Button, Form } from "react-bootstrap";
import OperatorModifyConfirmPop from "./operatorModifyConfirmPop";
import OperatorAddConfirmPop from "./operatorAddConfirmPop";

const ImagePage = ({ setAuth2 }) => {
    const baseUrl = `https://iacss.smtglobal.com.hk:3001`
    const [company, setCompany] = useState("");
    const [companyDisplayName, setCompanyDisplayName] = useState("");
    const [companyId, setCompanyId] = useState(0);
    const [companylist, setCompanylist] = useState([]);
    const [change, setChange] = useState(false);
    const [searchSonumber, setSearchSonumber] = useState("");
    const [searchPalletId, setSearchPalletId] = useState("");
    const [images, setImages] = useState([])
    const [type, setType] = useState("");
    const [loading, setLoading] = useState(false);



    const reset = async () => {
        setSearchSonumber("")
        setCompany("")
        setSearchPalletId("")
        setImages([])
        setType("All")
        setChange(!change)
    }

    const setSearchInfo = async (soNumber, company) => {
        setSearchSonumber(soNumber)
        setCompanyDetail(company)
    }


    function handleCallback() {
        setChange(!change)
    }


    const setCompanyDetail = async (company) => {
        setCompany(company)
        var companyDisplayName = companylist.filter(c => c.username == company);
        console.log(company);
        console.log(companyDisplayName);
        if (companyDisplayName.length > 0) {
            setCompanyDisplayName(companyDisplayName[0].display_name);
            setCompanyId(companyDisplayName[0].uid)
        }

    }



    const getCompany = async () => {
        try {
            const res = await fetch(baseUrl + `/api/company/name`);
            const jsonData = await res.json();

            setCompanylist(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    };

    const imageSearch = async () => {
        try {
            setImages([])
            setLoading(true)
            const response1 = await fetch(`https://iacss.smtglobal.com.hk:3001/api/inspection/bulkImage/${company}/${searchSonumber}/${searchPalletId}/${type?type:"All"}`);
            const jsonData1 = await response1.json();
            console.log(jsonData1)
            if(jsonData1 == null || jsonData1 =="fetch fail"){
                alert("No image found")
            }else{
                setImages(jsonData1);
	    }
            setLoading(false)
        } catch (err) {
            alert("No image found")
            setLoading(false)
            console.error(err.message);
        }
    };



    useEffect(() => {
        getCompany();
    }, [change]);


    const logout = async e => {
        try {
            e.preventDefault();
            localStorage.removeItem("token");
            setAuth2(false);
        } catch (err) {
            console.error(err.message);
        }

    };


    return (
        <Fragment>

            <Row>

                <Col sm={10}>
                    <h1 align="left" >Inspection Page 檢測頁面</h1>
                </Col>

                <Col sm={2} >
                    <p align="right">
                        <button onClick={e => logout(e)} className="btn btn-primary">
                            Operator Logout <br></br> 操作員登出
                        </button>
                    </p>
                </Col>

            </Row>

            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalText">
                    <Form.Label column sm="auto" xs={4} style={{ marginTop: '10px' }}>
                        SO number:
                    </Form.Label>
                    <Col sm={2} xs={8} style={{ marginTop: '10px' }}>
                        <Form.Control type="text" value={searchSonumber} onChange={e => {
                            setSearchSonumber(e.target.value.toUpperCase());
                        }} />
                    </Col>
                    
                    <Form.Label column sm="auto" xs={4} style={{ marginTop: '10px' }}>
                        pallet Id:
                    </Form.Label>
                    <Col sm={2} xs={8} style={{ marginTop: '10px' }}>
                        <Form.Control type="number" value={searchPalletId} onChange={e => {
                            setSearchPalletId(e.target.value.toUpperCase());
                        }} />
                    </Col>
                </Form.Group>

        
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalSelect">
                    <Form.Label column sm="auto" xs={5}>Select company 選擇公司:</Form.Label>
                    <Col sm={3} xs={7}>
                        <Form.Control
                            name="company"
                            value={company}
                            className="form-control"
                            onChange={e => {
                                setCompanyDetail(e.target.value);
                            }}
                            as="select"
                        >
                            <option value=""> -Select 選擇-</option>
                            {companylist.map(company => (
                                <option key={company.username} value={company.username}>{company.display_name}</option>

                            ))}
                        </Form.Control>
                    </Col>

                    <Form.Label column sm="auto" xs={4}>filter 篩選:</Form.Label>
                    <Col sm={2} xs={8}>
                        <Form.Control
                            name="type"
                            value={type}
                            className="form-control"
                            onChange={e => {
                                setType(e.target.value);
                            }}
                            as="select"
                        >
                            <option value="All">All</option>
                            <option value="X-Ray-Detected">X-Ray</option>
                            <option value="Surface-Detected">Surface</option>
                        </Form.Control>
                    </Col>
                    
                </Form.Group>
            </Form>


            <Row>
                <p align="right">

                    <Button sm={2} variant="primary" disabled={loading} style={{ margin: '10px' }}
                        onClick={() => imageSearch()}
                    >Search 搜尋
                    </Button>

                    <Button sm={2} variant="dark" disabled={loading} style={{ margin: '10px' }}
                        onClick={() => reset()}
                    >Reset 重置
                    </Button>
                </p>
            </Row>
            <br></br>
            <br></br>
            <Row>

                {images.map(image =>
                (

                    <Col xs="auto" sm={3}>
                        <div key={image}>
                            <img src={`https://iacss.smtglobal.com.hk:3001/api/images/${image.s3_key}`} style={{ marginTop: "15%" }} width="150" height="150" />
                        </div>
                    </Col>

                ))}
            </Row>



        </Fragment>
    );
}

export default ImagePage;
