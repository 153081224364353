import React, { Fragment, useEffect, useState } from "react";
import { InputGroup, Container, Row, Col, Button, Table } from "react-bootstrap";
import axios from 'axios'
import ClientEdit from "./clientEdit";
import OrderDetail from "./orderDetail";
import {
  Link, useHistory
} from "react-router-dom";

import ExportToExcel from './exportexcel.js'
//import './clientpage.css';

const Clientpage = ({ setAuth1 }) => {
  //variables
  const [orderlist, setOrderlist] = useState([]);
  const [sonumber, setSearch] = useState("");

  //const [username, setUser] = useState([]);
  const [username, setUser] = useState("");
  const [userdisplayName, setUserdisplayName] = useState("");
  const [images, setImages] = useState([])
  const [refreshKey, setRefreshKey] = useState(1);
  const [flag, setFlag] = useState("0");
  //user permission
  const [permission, setPermission] = useState([]);
  const [editAwbPermission, setEditAwbPermission] = useState(false);


  // const [isShowSearchExcel, setShowSearchExcel] = useState("0");
  //show how many data
  const [show_data, setShow_data] = useState(0);
  //set max data show
  const [max_show_data, setMax_show_data] = useState(10);
  //show view data page number
  //const [page_show_data, setPage_show_data] = useState(() => check_page_number());
  const [page_show_data, setPage_show_data] = useState(0);
  //export excel
  const [data, setData] = useState([])
  const [Change, setChange] = useState(false);

  const baseUrl = `https://iacss.smtglobal.com.hk:3001`

  const handleRefresh = () => {
    window.location.reload();
  };


  //check to see what the page number shd be
  function check_page_number(get_orderlist) {
    //console.log('order: ', orderlist)
    if (get_orderlist.length != 0) {
      setPage_show_data(1);
    } else if (get_orderlist.length == 0) {
      setPage_show_data(0);
    }
  }


  const fetchData = async (username) => {
    try {
      const response = await fetch(baseUrl + `/api/exportexcel/${username}`);
      const jsonData = await response.json();
      setData(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };

  //get username
  const getUser = async () => {
    try {
      const response = await fetch(baseUrl + "/api/dashboard/client", {
        method: "GET",
        headers: { jwt_token: localStorage.token }
      });
      const parseData = await response.json();
      console.log("getUser: " + parseData.username)
      setUser(parseData.username);
      setUserdisplayName(parseData.display_name);
      fetchData(parseData.username);
      getPermission(parseData.username);
    } catch (err) {
      console.error(err.message);
    }
  };

  //get permission
  const getPermission = async (username) => {
    try {
      const response = await fetch(baseUrl + `/api/user/permission/${username}`, {
        method: "GET",
        headers: { jwt_token: localStorage.token }
      });
      const parseData = await response.json();
      setPermission(parseData);
      if (parseData != null && parseData.length > 0) {
        for (let i = 0; i <= permission.length - 1; i++) {
          console.log("getPermission: " + parseData[i].user_permission);
          if ("edit-awb" == parseData[i].user_permission) {
            setEditAwbPermission(true);
          }
        }
      }
    } catch (err) {
      console.error(err.message);
    }
  };


  //get order by sonumber
  const getsonumber = async (username, sonumber) => {
    try {
      setShow_data(0);
      console.log(username);
      console.log(sonumber);
      if((username !=null && username!="") && (sonumber != null && sonumber != "")){
        const response1 = await fetch(baseUrl + `/api/orderlist/${username}/${sonumber}`);
        const jsonData1 = await response1.json();
        console.log(jsonData1);
        
        if (jsonData1.length <= 0) {
          setFlag("0");
          alert("Please input correct SO number! 請輸入正確SO number!");
          check_page_number(jsonData1);
          setOrderlist([]);
        }
        else if (jsonData1.length > 0) {
          var orders = jsonData1.filter(data => data.data_source !="SOCOMP")
          setOrderlist(orders);
          check_page_number(jsonData1);
          setFlag("1");
        };
      }else{
        alert("Please input correct SO number! 請輸入正確SO number!");
        setPage_show_data(0);
        setOrderlist([]);
      }
      
    } catch (err) {
      console.error(err.message);
    }
  };

  const refreshOrder = async (username, sonumber) => {
    try {
      if ((username != null && username != "") && (sonumber != null && sonumber != "")) {
        const response1 = await fetch(baseUrl + `/api/orderlist/${username}/${sonumber}`);
        const jsonData1 = await response1.json();
        console.log(jsonData1);
        if (jsonData1.length <= 0) {
          setFlag("0");
        }
        else if (jsonData1.length > 0) {
          setFlag("1");
          var orders = jsonData1.filter(data => data.data_source !="SOCOMP")
          setOrderlist(orders);
        };
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  //reset
  // const reset = async ()=>{
  //     try {

  //       const response1 = await fetch(baseUrl+`/api/${username}`);
  //       const jsonData1 = await response1.json();
  //       setOrderlist(jsonData1);
  //     } catch (err) {
  //       console.error(err.message);
  //     }
  //   }

  //logout

  const logout = async e => {
    try {
      e.preventDefault();
      localStorage.removeItem("token");
      setAuth1(false);
    } catch (err) {
      console.error(err.message);
    }

  };

  const imagename = async (sonumber) => {
    try {
      const response1 = await fetch(baseUrl + `/api/key/${sonumber}`);
      const jsonData1 = await response1.json();
      //console.log(jsonData1)
      setImages(jsonData1);
    } catch (err) {
      console.error(err.message);
    }
  };

  // let check = false;
  //     const getSchedule = () => {
  //     check = true;
  //     //console.log(check)
  // };

  useEffect(() => {
    getUser();
    setChange(false);
    refreshOrder(username, sonumber);
  }, [refreshKey, Change])



  const dateConverter = (move_diff_sec) => {
    const one_day = 60 * 60;
    let result
    result = (move_diff_sec) / (one_day);
    console.log(result);
    return result
  }

  //return components 
  return (
    <Fragment>
      <div>
        {" "}
        <p align="right">
          <button onClick={e => logout(e)} className="btn btn-primary">
            Client Logout <br></br> 顧客登出
          </button>
        </p>
        <p align="middle" style={{ fontSize: "200%" }}>Welcome 歡迎 {username} !</p>
        <br></br>
        <br></br>
        <div className="form-group col-md-6">
          <label>Search 搜尋 SO number:</label>
          <InputGroup>
            <input
              type="text"
              name="search"
              placeholder="SO number"
              className="form-control"
              value={sonumber.toUpperCase()}
              onChange={e => setSearch(e.target.value)}
            />
            <InputGroup.Append>
              <button onClick={() => { getsonumber(username, sonumber);setRefreshKey(refreshKey => refreshKey + 1) }} className="btn btn-primary">
                Search 搜尋
              </button></InputGroup.Append>
          </InputGroup></div>

        <div align='right'>
          <ExportToExcel apiData={data} username={username} searchSonumber={sonumber} isShowSearchExcel={flag} />
        </div>

        {/* <button onClick={() => reset()}className="btn btn-primary ml-3">
        Reset
      </button> */}

        <Table responsive>
          <thead>
            <tr align="center">
              <th>Status <br />貨物狀態</th>
              <th>SO number</th>
              <th>AWB</th>
              <th>L 長<br />(cm)</th>
              <th>W 寬<br />(cm)</th>
              <th>H 高<br />(cm)</th>
              <th>QTY <br />數量</th>
              <th>WT <br />重量</th>
              <th>Move In <br />入倉日期</th>
              <th>Storage Time <br />存倉時間</th>
              <th>Remarks <br />備註</th>
              {editAwbPermission ?
                <th>
                  Edit <br />編輯
                </th> : null
              }
            </tr>
          </thead>
          <tbody align="center">
            {orderlist.slice(show_data, show_data + max_show_data).map(orderlist => (
              <tr
                style={
                  orderlist.move_out != null
                    ? null
                    : { background: 'yellow' }}

                key={orderlist.order_id}>
                <td>{orderlist.move_out != null ? "Out 已出倉" : "In 存倉中"}</td>
                <td>{
                  <Link to={{ pathname: `/client/viewImage`, query: { order_id: orderlist.order_id, sonumber: orderlist.sonumber, remark: orderlist.remark, awb: orderlist.awb, userType:"client", companyName: username } }}>
                    <Button variant="link">
                      {orderlist.sonumber}
                    </Button>
                  </Link>
                }</td>
                <td>{orderlist.awb}</td>
                <td>{orderlist.length}</td>
                <td>{orderlist.width}</td>
                <td>{orderlist.height}</td>
                <td>{orderlist.qty}</td>
                <td>{orderlist.weight}</td>
                <td>{orderlist.move_in}</td>
                <td>{orderlist.move_out == null ? (Math.round(orderlist.move_in_day * 10) / 10) + "hrs (小時)" : null}</td>
                <td>{orderlist.remark != null ? "#" : null}</td>
                {editAwbPermission && !orderlist.move_out && orderlist.move_in_day < 48 ?
                  <td>
                    <ClientEdit confirmSource="client" orderlist={orderlist} user={username}  userdisplayName={userdisplayName} setChange={setChange} />
                  </td> : <td></td>
                }
              </tr>

            ))}
          </tbody>
        </Table>

        <InputGroup.Append>
          <button style={{ marginLeft: "auto" }} onClick={() => {
            //check if click "Previous", will show_data be minus to zero
            if (show_data != 0) {
              setShow_data(show_data - max_show_data);
              setPage_show_data(page_show_data - 1);
            }
          }} className="btn btn-primary">
            Previous 上一頁
          </button>
          <p style={{ margin: "10px" }}>Page {page_show_data} / {Math.ceil(orderlist.length / max_show_data)}</p>
          <button onClick={() => {
            //check if click "Next", will show_data larger than the SO order array length
            if (show_data + max_show_data <= orderlist.length-1) {
              setShow_data(show_data + max_show_data);
              setPage_show_data(page_show_data + 1);
            }
          }} className="btn btn-primary">
            Next 下一頁
          </button>
        </InputGroup.Append>

      </div>
    </Fragment>

  );
};

export default Clientpage;
