//upload x-ray image to AWS S.3
import { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation
} from "react-router-dom";
import { InputGroup, Container, Row, Col, Modal, Button, NavItem } from "react-bootstrap";
import folderLogo from './img/folder_2.png'


const OperatorFolderImage = () => {

  const { query } = useLocation();


  //const [description, setDescription] = useState("")
  const [description, setDescription] = useState("")
  const [userType, setUserType] = useState("")
  const [photoType, setPhotoType] = useState("")
  const [images, setImages] = useState([])
  const [order_id, setOrder_id] = useState(0)
  const [remark, setRemark] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [flag, setFlag] = useState("0");
  //console.log('staff page upload: ', order_id, query)

  const initQuery = async () => {
    if (query != null || typeof (query) !== "undefined") {
      console.log(query);
      console.log("id+" + query.order_id);
      setDescription(query.sonumber)
      setUserType(query.userType)
      setPhotoType(query.photoType)
      setOrder_id(query.order_id)
      setRemark(query.remark)
      setCompanyName(query.companyName)
      setCompanyDisplayName(query.companyDisplayName)
    } else {
      window.location.reload();
    }
  }

  const imagename = async () => {
    try {
      console.log("imagename");
      console.log(flag);
      //  if (flag === "0") {
      var type = ""
      if (photoType == "Move-In") {
        type = "Move-In"
      } else if (photoType == "Weighing") {
        type = "Weighing"
      } else if (photoType == "Surface") {
        type = "Surface"
      } else if (photoType == "X-Ray") {
        type = "X-Ray"
      }

      const response1 = await fetch(`https://iacss.smtglobal.com.hk:3001/api/imageKeys/${description}/${companyName}/${type}`);
      const jsonData1 = await response1.json();
      console.log(jsonData1)
      setImages(jsonData1);
      setFlag("1");
      //  }
    } catch (err) {
      console.error(err.message);
    }
  };

  const convertBase64ToPng = async (url) => {
    const base64 = await convertUrlToBase64(url)
    console.log(base64)
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      //const pngDataUrl = canvas.toDataURL('image/jpeg');
      console.log(image); // You can do something with the converted PNG data URL
    };

    image.src = base64;

    return image
  }

  const convertUrlToBase64 = async (url) => {
    const imageUrl = url; // Replace with your image URL

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Data = reader.result;
        return base64Data
      };

      // reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error converting URL to base64:', error);
    }
  };

  useEffect(() => {
    initQuery()
  }, [query]);

  useEffect(() => {
    imagename();
  }, [photoType]);

  return (

    <div>
      <div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </div>
      <br></br>

      <Row>
        <Col>
          <h3>{photoType == "Move-In" ? "Move in Photos 入倉相片" : null}
            {photoType == "Weighing" ? "Weighing Photos 磅相" : null}
            {photoType == "Surface" ? " IACSS Photos 相機相片" : null}
            {photoType == "X-Ray" ? "X-ray Photos X光相片" : null}
          </h3>
        </Col>

        <Col>
          <p align="right">
            <Link to={{ pathname: `/${userType}/operatorImageUpload/`, query: { order_id: order_id, sonumber: description, companyDisplayName: companyDisplayName, companyName: companyName, userType: userType, photoType: photoType } }}>
              <Button variant="success" >
                Upload images <br></br>上傳相片
              </Button>
            </Link>
          </p>
        </Col>
      </Row>
      <br></br>
      <h3>SO number: {description}</h3> <br />
      <h3>Company 公司: {companyDisplayName}</h3>
      <br></br>


      <br></br>
      <p align="right">
        {userType == "operator" ?
          <Link to="/operator/operatorPage">
            <button type="button" className="btn btn-dark ml-3">
              Back to Operator Insert Page <br></br>返回操作員輸入頁面
            </button>
          </Link> :
          <Link to={{ pathname: `/staff/operatorImageMain`, query: { sonumber: description, companyDisplayName: companyDisplayName, companyName: companyName, orderlist: [], userType: "staff" } }}>
            <button type="button" className="btn btn-dark ml-3">
              Back to the previous page 返回上一頁
            </button>
          </Link>
        }
      </p>


      <br></br>
      <br></br>


      <Row>
        {images.map(image =>
        (

          <Col xs="auto" sm={3}>
            <div key={image}>
              <img src={`https://iacss.smtglobal.com.hk:3001/api/images/${image.key}`} style={{ marginTop: "15%" }} width="150" height="150" />
            </div>
          </Col>

        ))}
      </Row>

    </div>


  );
};

export default OperatorFolderImage;
