import React, { Fragment, useState, useEffect } from "react";
import { produce } from "immer";
import { InputGroup, Container, Row, Col, Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import {
  Link, useLocation, useHistory
} from "react-router-dom";
import axios from 'axios'

const OperatorItemImageUploadConfirmPop = ({ orderId, bulkRecordUid, increment, sonumber, companyDisplayName, company, files, userType, photoType, setChange }) => {


  const baseUrl = `https://iacss.smtglobal.com.hk:3001`
  const [show, setShow] = useState();
  const [orderlist, setOrder] = useState([]);
  const history = useHistory();
  var regEx = /^(?!\s)[A-Z0-9\s]+$/i;


  const handleClose = () => {

    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }

  const uploadingNotify = () => toast.success('Images uploading! 圖片上載中!', {
    toastId: 2,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    //draggable: true,
    progress: undefined
  });

  const uploadedNotify = () => toast.success('Images uploaded! 圖片已上載!', {
    toastId: 3,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    //draggable: true,
    progress: undefined
  });

  const uploadedFailNotify = () => toast.success('Images upload fail! 圖片上載失敗!', {
    toastId: 3,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    //draggable: true,
    progress: undefined
  });





  const confirm = async () => {
    console.log(orderId)
    console.log(sonumber)
    console.log(files)

    if (files.length > 0) {
      console.log("uploading")
      uploadingNotify();
      console.log("uploadingNotify")
    }
    var type = ""
    if (photoType == "Move-In") {
      type = "Move-In"
    } else if (photoType == "Weighing") {
      type = "Weighing"
    } else if (photoType == "Surface") {
      type = "Surface"
    } else if (photoType == "X-Ray") {
      type = "X-Ray"
    }

    for (let i = 0; i < files.length; i++) {
      const result = await postImage({ image: files[i], bulkRecordUid, type })
    }


    uploadedNotify();
    console.log("upload completed")
    setChange(true)
    history.push({ pathname: `/${userType}/operatorFolderItemImage/`, query: { order_id: orderId, bulkRecordUid: bulkRecordUid, increment: increment, sonumber: sonumber, companyDisplayName: companyDisplayName, companyName: company, userType: userType, photoType: photoType } });
  }




  async function postImage({ image, bulkRecordUid, type }) {
    console.log(image);
    const formData = new FormData();
    formData.append("image", image)
    formData.append("bulkRecordUid", bulkRecordUid)
    formData.append("type", type)
    const result = await axios.post('https://iacss.smtglobal.com.hk:3001/api/bulkImage', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).catch(err => { if (err) { uploadedFailNotify() } })
    return result.data
  }

  return (
    <Fragment>



      <Button variant="primary" onClick={handleShow}>
        Upload 上傳
      </Button>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm  確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div> Confirm upload {files.length} image to {sonumber} with  {company} ?
          </div>

          <div> 確定上傳{files.length}張相片至{company} {sonumber}?
          </div>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={confirm} data-dismiss="modal">
            Confirm 確定
          </Button>


          <Button variant="secondary" onClick={handleClose}>
            Cancel 取消
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default OperatorItemImageUploadConfirmPop;
