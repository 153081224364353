import React, { Fragment, useEffect, useState } from "react";
import { Button, Container, Form, Modal, Table, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import Input from "./input";
import Edit from "./edit";
import Delete_alert from "./delete_alert";
import OrderDetail from "./orderDetail";
import OrderListExportToExcel from './orderListExportExcel.js'
import {
  Link, useHistory
} from "react-router-dom";
import * as Icon from "react-icons/ai";

const Orderlist = ({ setAuth }) => {
  const [orderlist, setOrder] = useState([]);
  const [sonumber, setSearch] = useState("");
  const [companylist, setCompanylist] = useState([]);
  const [remarkList, setRemarkList] = useState([]);
  const [company, setCompany] = useState("");
  const [Change, setChange] = useState(false);
  //show how many data
  const [show_data, setShow_data] = useState(0);
  //set max data show
  const [max_show_data, setMax_show_data] = useState(10);
  //show view data page number
  const [page_show_data, setPage_show_data] = useState();

  //get permission
  const [username, setUser] = useState("");
  const [permission, setPermission] = useState([]);
  const [userAccount, setUserAccount] = useState("");
  const [editFullPermission, setEditFullPermission] = useState(false);

  //export excel
  const [data, setData] = useState([])

  const baseUrl = `https://iacss.smtglobal.com.hk:3001`
  const history = useHistory();
  const dropdownIcon = Icon.AiOutlineMenu;

  //check to see what the page number shd be
  function check_page_number(get_orderlist) {
    if (get_orderlist.length != 0) {
      setPage_show_data(1);
    } else if (get_orderlist.length == 0) {
      setPage_show_data(0);
    }
  }

  const getCompany = async () => {
    try {
      const res = await fetch(baseUrl + `/api/company/name`);
      const jsonData = await res.json();
      console.log(jsonData);
      setCompanylist(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchData = async (username) => {
    try {

      
      console.log(company,sonumber);
      if (username == "all" || (username == "sonumber" && company =="")) {
        const response = await fetch(baseUrl + `/api/allExportexcel/all`);
        const jsonData = await response.json();
        console.log('page no: ', jsonData);
        setData(jsonData);
      } else if (username == "del_so") {
        const response = await fetch(baseUrl + `/api/isDeletedExportexcel/isDeleted`);
        const jsonData = await response.json();
        console.log('page no: ', jsonData);
        setData(jsonData);
      } else if (username == "move_out") {
        const response = await fetch(baseUrl + `/api/isMoveOutExportexcel/isMoveOut`);
        const jsonData = await response.json();
        console.log('page no: ', jsonData);
        setData(jsonData);
      } else if(username != "sonumber") {
        const response = await fetch(baseUrl + `/api/exportexcel/${username}`);
        const jsonData = await response.json();
        setData(jsonData);
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  //delete item
  {/*const deleteTodo = async id => {
      alert('Check out!!!')
      try {
        const deleteTodo = await fetch(baseUrl+`/api/orderlist/${id}`, {
          method: "DELETE"
        });
  
        setOrder(orderlist.filter(orderlist => orderlist.order_id !== id));
      } catch (err) {
        console.error(err.message);
      }
    };*/}

  //update is_delete to True
  const is_deleteTodo = async id => {
    alert('Check out!!!')
    try {
      const deleteTodo = await fetch(baseUrl + `/api/orderlist/is_delete/${id}`, {
        method: "PUT"
      });

      //filter out the pressed "order id", not delete that "order id"
      setOrder(orderlist.filter(orderlist => orderlist.order_id !== id));
    } catch (err) {
      console.error(err.message);
    }
  };

  const getTodos = async () => {
    try {
      setShow_data(0);
      const response = await fetch(baseUrl + "/api/orderlist");
      const jsonData = await response.json();

      setOrder(jsonData);
      console.log(jsonData);
      check_page_number(jsonData);

    } catch (err) {
      console.error(err.message);
    }
  };

  //get username
  const getUser = async () => {
    try {
      const response = await fetch(baseUrl + "/api/dashboard/staff", {
        method: "GET",
        headers: { jwt_token: localStorage.token }
      });
      const parseData = await response.json();
      console.log("getUser: " + parseData.username)
      setUser(parseData.username);
      getPermission(parseData.username);
    } catch (err) {
      console.error(err.message);
    }
  };


  //get permission
  const getPermission = async (username) => {
    try {
      const response = await fetch(baseUrl + `/api/user/permission/${username}`, {
        method: "GET",
        headers: { jwt_token: localStorage.token }
      });
      const parseData = await response.json();
      setPermission(parseData);
      console.log("getPermission: " + parseData.length);
      if (parseData != null && parseData.length > 0) {
        for (let i = 0; i <= parseData.length - 1; i++) {
          console.log("getPermission: " + parseData[i].user_permission);
          if ("edit-full" == parseData[i].user_permission) {
            console.log("setEditFullPermission: true");
            setEditFullPermission(true);
          }
          setUserAccount(parseData[i].user_account)
          console.log("getUserAccount: " + parseData[i].user_account);
        }
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const getsonumber = async (get_company, sonumber) => {
    try {
      const slength = sonumber.length;
      const ulength = get_company.length;
      setShow_data(0);
      //console.log(get_company);

      if (slength > 0 && ulength > 0) {
        if (get_company == "all") {
          //console.log("here is if" + {get_company} + {sonumber})
          const response1 = await fetch(baseUrl + `/api/orderlist/item/${sonumber}`);
          const jsonData1 = await response1.json();
          if (!(jsonData1.length > 0)) {
            alert("No SO number found 沒有相關記錄")
          }
          setOrder(jsonData1);
          check_page_number(jsonData1);
        }
        else if (get_company == "del_so") {
          //console.log("here is if" + {get_company} + {sonumber})
          const response1 = await fetch(baseUrl + `/api/show_by_so/is_delete/${sonumber}`);
          const jsonData1 = await response1.json();
          if (!(jsonData1.length > 0)) {
            alert("No SO number found 沒有相關記錄")
          }
          setOrder(jsonData1);
          check_page_number(jsonData1);
        }else if (get_company == "move_out") {
          const response1 = await fetch(baseUrl + `/api/show_by_so/move_out/${sonumber}`);
          const jsonData1 = await response1.json();
          if (!(jsonData1.length > 0)) {
            alert("No SO number found 沒有相關記錄")
          }
          setOrder(jsonData1);
          check_page_number(jsonData1);
        }
        else {
          console.log("here is else" + { get_company } + { sonumber })
          const response1 = await fetch(baseUrl + `/api/orderlist/staffitem/${get_company}/${sonumber}`);
          const jsonData1 = await response1.json();
          if (!(jsonData1.length > 0)) {
            alert("No SO number found 沒有相關記錄")
          }
          setOrder(jsonData1);
          check_page_number(jsonData1);
        }
      }

      else if (slength <= 0 && ulength > 0) {
        if (get_company == "all") {
          const response1 = await fetch(baseUrl + `/api/orderlist`);
          const jsonData1 = await response1.json();
          if (!(jsonData1.length > 0)) {
            alert("No SO number found 沒有相關記錄")
          }
          setOrder(jsonData1);
          check_page_number(jsonData1);
        }
        else if (get_company == "del_so") {
          const response1 = await fetch(baseUrl + `/api/show_all/is_delete`);
          const jsonData1 = await response1.json();
          if (!(jsonData1.length > 0)) {
            alert("No SO number found 沒有相關記錄")
          }
          setOrder(jsonData1);
          check_page_number(jsonData1);
        }else if (get_company == "move_out") {
          const response1 = await fetch(baseUrl + `/api/show_all/move_out`);
          const jsonData1 = await response1.json();
          if (!(jsonData1.length > 0)) {
            alert("No SO number found 沒有相關記錄")
          }
          setOrder(jsonData1);
          check_page_number(jsonData1);
        }
        else {
          const response1 = await fetch(baseUrl + `/api/${get_company}`);
          const jsonData1 = await response1.json();
          if (!(jsonData1.length > 0)) {
            alert("No SO number found 沒有相關記錄")
          }
          setOrder(jsonData1);
          check_page_number(jsonData1);
        }

      } else if (slength > 0 && ulength <= 0) {
        const response1 = await fetch(baseUrl + `/api/orderlist/item/${sonumber}`);
        const jsonData1 = await response1.json();
        if (!(jsonData1.length > 0)) {
          alert("No SO number found 沒有相關記錄")
        }
        setOrder(jsonData1);
        check_page_number(jsonData1);
      } else {
        alert("Please input SO number or select company! 請輸入SO number或公司名稱!");
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  const logout = async e => {
    try {
      e.preventDefault();
      localStorage.removeItem("token");
      setAuth(false);

    } catch (err) {
      console.error(err.message);
    }

  };

  const reset = async () => {
    setSearch("");
    setCompany("");
    getTodos();
  }

  const soNumberHistory = async (e) => {
    e.preventDefault();
    if (sonumber && company) {
      console.log(sonumber);
      console.log(company);
      console.log(orderlist);
      const soNumberHistoryData = orderlist.filter(orderlist => orderlist.sonumber == sonumber && orderlist.companyname == company);
      console.log(soNumberHistoryData);
      if (soNumberHistoryData.length > 0) {
        history.push({ pathname: `/staff/soNumberHistory/`, query: { order_id: orderlist.order_id, sonumber: orderlist.sonumber, data: soNumberHistoryData } });
      } else {
        alert("No so number history found(沒有相關記錄)");
      }

    } else {
      alert("SO number and company must be selected. 請提供SO number及公司名稱.");
    }

  }


  const mutiEditPage = async (e) => {
    e.preventDefault();
    history.push({ pathname: `/staff/mutiEditPage/`, query: { order_id: orderlist.order_id, sonumber: orderlist.sonumber, data: orderlist, companylist: companylist , userAccount:userAccount} });
  }

  useEffect(() => {
    getTodos();
    getCompany();
    getUser();
    setChange(false);
  }, [Change]);

  //console.log(orderlist);

  return (
    <Fragment>
      <div>
        <p align="right">
          {localStorage.userAccount == "admin" ?
            <button onClick={e => logout(e)} className="btn btn-primary">
              Admin Logout <br></br>管理員登出
            </button> :
            <button onClick={e => logout(e)} className="btn btn-primary">
              Staff Logout <br></br>職員登出
            </button>
          }
        </p>

        <button onClick={(e) => mutiEditPage(e)} className="btn btn-primary" align="left">
          Multi-edit SO number <br></br> 多項編輯
        </button>
      </div>
      <br></br>
      {/* <Link to = "/staff/search" className = "btn btn-primary">Search</Link> 
      <Link to = "/staff/add" className = "btn btn-primary ml-3">Add</Link>  */}


      <br></br>

      <label>Search 搜尋 SO number:</label>
      <input
        type="text"
        name="search"
        placeholder="SO number"
        className="form-control"
        value={sonumber.toUpperCase()}
        onChange={e => {setSearch(e.target.value);
          fetchData("sonumber");
        }}
      />
      <Form.Group>
        <Form.Label>Select company 選擇公司:</Form.Label>
        <Form.Control
          name="company"
          value={company}
          className="form-control"
          onChange={e => {
            setCompany(e.target.value);
            fetchData(e.target.value);
          }}
          as="select"
        >
          <option value=""> -- Select an option 選擇 --</option>
          <option value="all">All</option>
          <option value="del_so">Deleted SO</option>
          <option value="move_out">Move out</option>
          {companylist.map(company => (
            <option key={company.username} value={company.username}>{company.display_name}</option>

          ))}
        </Form.Control>
      </Form.Group>
      <button onClick={() => getsonumber(company, sonumber)} className="btn btn-dark ml-3">
        Search 搜尋
      </button>

      <button onClick={() => reset()} className="btn btn-dark ml-3">
        Reset 重置
      </button>

      <button onClick={(e) => soNumberHistory(e)} className="btn btn-primary ml-3">
        SO number history 記錄
      </button>


      {(company || sonumber)?
        <div align='right'>
          <OrderListExportToExcel apiData={data} username={company} searchSonumber={sonumber} isShowSearchExcel={null} />
        </div>
        : null
      }

      {/*<Link to="/staff/uploadimage">
     <button type="button" className="btn btn-dark ml-3">
          Upload Image 上載相片
     </button>
     </Link>*/}
      <Input setChange={setChange} companylist={companylist} />
      <Table responsive>
        <thead>
          <tr align="center">
            <th>Company <br />公司</th>
            <th>SO number</th>
            <th>AWB</th>
            <th>L長<br />(cm)</th>
            <th>W寬<br />(cm)</th>
            <th>H高<br />(cm)</th>
            <th>QTY<br />數量</th>
            <th>WT <br />重量</th>
            <th>CBM </th>
            <th>Vol. <br />(cbm)</th>
            <th>Move in <br />入倉日期</th>
            <th>Move out <br />出倉日期</th>
            <th>Remarks <br />備註</th>
            <th></th>
          </tr>
        </thead>
        <tbody align="center">
          {orderlist.slice(show_data, show_data + max_show_data).map(orderlist => (
            <tr
              style={
                ( orderlist.data_source == "SOCOMP" )
                  ? { background: '#EAF803' }
                  : orderlist.move_out != null? { background: '#e59c96' } : null}

              key={orderlist.order_id}>
              <td>{orderlist.companyname} </td>
              <td>{
                <Link to={{ pathname: `/staff/viewImage/`, query: { order_id: orderlist.order_id, sonumber: orderlist.sonumber, remark: orderlist.remark,companyDisplayName: orderlist.display_name ,companyName: orderlist.companyname, userType: "staff" } }}>
                  <Button variant="link">
                    {orderlist.sonumber}
                  </Button>
                </Link>
              }</td>
              <td>{orderlist.awb}</td>
              <td>{orderlist.length}</td>
              <td>{orderlist.width}</td>
              <td>{orderlist.height}</td>
              <td>{orderlist.qty}</td>
              <td>{orderlist.weight}</td>
              <td>{Number((orderlist.qty * orderlist.length * orderlist.width * orderlist.height) / 1000000).toFixed(2)}</td>
              <td>{Number((orderlist.qty * orderlist.length * orderlist.width * orderlist.height) / 1000000 * 167).toFixed(2)}</td>
              <td>{orderlist.move_in}</td>
              <td>{orderlist.move_out}</td>
              <td>{(orderlist.remark != null && orderlist.remark != "") ? "#" : null}</td>
              <td>
                <DropdownButton id="dropdown-basic-button" title={dropdownIcon}>
                  <Dropdown.Item>
                    <Link to={{ pathname: `/staff/operatorImageMain`, query: { sonumber: orderlist.sonumber, order_id: orderlist.order_id,companyDisplayName: orderlist.display_name ,companyName: orderlist.companyname, orderlist: orderlist, userType: "staff" } }}>
                      <button style={{height: '40px', width : '120px'}} type="button" className="btn btn-dark">Upload 上傳</button>
                    </Link>
                  </Dropdown.Item>

                 { userAccount == "admin"?
                  <Dropdown.Item> <Edit confirmSource="orderList" orderlist={orderlist} companyList={companylist} setChange={setChange} userPermission={editFullPermission} userAccount={userAccount} /></Dropdown.Item>
                  :null
                 }
                 
                  <Dropdown.Item> <Delete_alert order_id={orderlist.order_id} sonumber={orderlist.sonumber} userAccount={userAccount} order_data_source={orderlist.data_source} setChange={setChange} /></Dropdown.Item>
                </DropdownButton>

              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <InputGroup.Append>
        <button style={{ marginLeft: "auto" }} onClick={() => {
          //check if show_data will be minus to zero
          if (show_data != 0) {
            setShow_data(show_data - max_show_data);
            setPage_show_data(page_show_data - 1);
          }
        }} className="btn btn-primary">
          Previous上一頁
        </button>
        <p style={{ margin: "10px" }} >Page {page_show_data} / {Math.ceil(orderlist.length / max_show_data)}</p>
        <button onClick={() => {
          //check if show_data will larger than the SO order array length
          if (show_data + max_show_data <= orderlist.length -1) {
            setShow_data(show_data + max_show_data);
            setPage_show_data(page_show_data + 1);
           // console.log('bigger than array'+orderlist.length);
          }
        }} className="btn btn-primary">
          Next下一頁
        </button>
      </InputGroup.Append>
    </Fragment>
  );
};

export default Orderlist;
