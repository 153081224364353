//upload x-ray image to AWS S.3
import { Fragment, useState ,useEffect} from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation
} from "react-router-dom";
import {InputGroup, Container, Row, Col , Modal , Button, NavItem} from "react-bootstrap";

const SoNumberHistory = () => {

  const {query } = useLocation();


  //const [description, setDescription] = useState("")
  const [description, setDescription] = useState(query.sonumber)
  const [images, setImages] = useState([])
  const [order_id, setOrder_id] = useState(query.order_id)
  const [data, setData] = useState(query.data)
  const [flag, setFlag] = useState("0");
  //console.log('staff page upload: ', order_id, query)

  

  return (
    <div>
      <div>
      <ToastContainer position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover/>
      </div>
      <br></br>
      <h3>SO number history 記錄</h3> 
      <br></br>
    
      <table class="table table-striped mt-5 text-center">
          <thead>
            <tr>
              <th>Company <br/>公司</th>
              <th>SO number</th>
              <th>Move in <br/>入倉日期</th>
              <th>Move out <br/>出倉日期</th>
            </tr>
          </thead>
          <tbody>
            {data.map(data => (
              <tr 
              key={data.order_id}>
                <td>{data.companyname} </td>
                <td>{data.sonumber}</td>
                <td>{data.move_in}</td>
                <td>{data.move_out}</td>
              </tr>
            ))}
          </tbody>
        </table>



    <br></br>
      <p align = "right">
      <Link to="/staff/orderlist">
      <button type="button" className="btn btn-dark ml-3">
        Back to Order List 返回訂單表
      </button>
      </Link>
      </p>

    </div>

    
  );
};

export default SoNumberHistory;
