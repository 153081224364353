//export csv data fo sonumber
import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportToExcel = ({ apiData, username , searchSonumber , isShowSearchExcel }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (apiData, username , searchSonumber, isShowSearchExcel) => {
    //apiData already contain all that user company SO number data, 
    //when press "Export searched SO number", below will just filter the searched SO number (is still using the same apiData)
    const baseUrl = `https://iacss.smtglobal.com.hk:3001`
    if(searchSonumber != null){
   
      const response1 = await fetch(baseUrl + `/api/show/search/${username}/${searchSonumber}`);
      const jsonData1 = await response1.json();
      var p2Data = jsonData1.map( x => ({sonumber : x.sonumber, awb: x.awb , qty:x.qty , weight:x.weight,length:x.length, width:x.width,height:x.height,move_in:x.move_in, move_out:x.move_out,storage_hour:x.storage_hour!=null?x.storage_hour:x.move_in_day , remarks:x.remark}))
      apiData = p2Data;
    }else{
      const response1 = await fetch(baseUrl + `/api/show/all/${username}`);
      const jsonData1 = await response1.json();
      apiData = jsonData1;
      searchSonumber ="";
    }

    console.log(apiData)
    console.log(searchSonumber)
    console.log(isShowSearchExcel)
    
   
    const now_date = new Date();
    const date = now_date.getDate();
    const month = now_date.getMonth()+1;
    const year = now_date.getFullYear();
    const full_date = "_" + date.toString() +'_'+ month.toString() +'_'+ year.toString()    

    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(apiData);
    //const wb = { Sheets: { data: ws }, SheetNames: ["V1_P.1"] };
    XLSX.utils.book_append_sheet(wb , ws , "V1_p.1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, username+"_" + searchSonumber + full_date + fileExtension);
  };


  const getAllData = async (username) => {
    const response1 = await fetch(`https://iacss.smtglobal.com.hk:3001/api/show/all/${username}`);
    const jsonData1 = await response1.json();
    return jsonData1;
  }

  const getstock_in = async (username) => {
    try {
      //If need to adjust the checking in_stock date(e.g. 3month), please adjust in server.js in 'server' directory
      const response1 = await fetch(`https://iacss.smtglobal.com.hk:3001/api/show/in_stock/${username}`);
      const jsonData1 = await response1.json();

      //download date for rename file
      const now_date = new Date();
      const date = now_date.getDate();
      const month = now_date.getMonth()+1;
      const year = now_date.getFullYear();
      const full_date = "_" + date.toString() +'_'+ month.toString() +'_'+ year.toString()
       
      //console.log(jsonData1);
      //setStock_in(jsonData1);

      if (jsonData1.length == 0){
        var excel_json = [{Notice:'No SO number is storing longer than 3 months 沒有SO number存放超過3個月'}];
      } else if (jsonData1.length >= 1){
        excel_json = jsonData1.map( x => ({sonumber : x.sonumber, awb: x.awb , qty:x.qty , weight:x.weight,length:x.length, width:x.width,height:x.height,move_in:x.move_in, move_out:x.move_out,storage_hour:x.storage_hour!=null?x.storage_hour:x.move_in_day , remarks:x.remark}))
      }
      var wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excel_json);
      XLSX.utils.book_append_sheet(wb , ws , "V1_p.1");
     // const wb = { Sheets: { data: ws }, SheetNames: ["V1_P.1"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      console.log(data);
      FileSaver.saveAs(data, username + '_in_stock_' + full_date + fileExtension);    
    } catch (err) {
      console.error(err.message);
    }

  };  


  return (
    <div align = 'right' > 
     { (isShowSearchExcel == 1) ? <button type="button" className="btn btn-success" onClick={(e) => exportToCSV(apiData, username , searchSonumber ,isShowSearchExcel)}>Export searched SO number<br/>匯出已搜尋SO number</button> : null } &nbsp;
    <button type="button" className="btn btn-success" onClick={(e) => exportToCSV(apiData, username, null ,isShowSearchExcel)}>Export all SO number<br/>匯出所有SO number</button> &nbsp;
    <button type="button" className="btn btn-success" onClick={(e) => getstock_in(username)}>Export over 3 months SO number<br/>匯出已存放超過3個月SO number</button> 
    </div>
  );
};

export default ExportToExcel;
