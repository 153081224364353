//export csv data fo sonumber
import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const OrderListExportToExcel = ({ apiData, username , searchSonumber , isShowSearchExcel }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, username , searchSonumber, isShowSearchExcel) => {
    //apiData already contain all that user company SO number data, 
    //when press "Export searched SO number", below will just filter the searched SO number (is still using the same apiData)
   console.log(apiData)
    if(searchSonumber != null && searchSonumber != ""){
      apiData = apiData.filter(apiData => apiData.sonumber == searchSonumber );
    }
    console.log(searchSonumber)
    console.log(apiData)

    const now_date = new Date();
    const date = now_date.getDate();
    const month = now_date.getMonth()+1;
    const year = now_date.getFullYear();
    const full_date ='_'+ date.toString() +'_'+ month.toString() +'_'+ year.toString() 
    const empty_data = {display_name: "" , sonumber:"" ,  awb:"" , qty:"", weight:"" ,length:"" , width:"", height:"", CBM:"" , vol_cbm:"", move_in : "" , move_out: "" , remarks:""}


    var wb = XLSX.utils.book_new();

    var p1Data = apiData.reduce((acc,rec) => {
      if (acc.length > 0 && acc[acc.length-1].sonumber == rec.sonumber && acc[acc.length-1].display_name == rec.display_name) {
        acc = [...acc,{...rec}]
      }
      else if(acc.length <= 0){
        acc = [...acc,{...rec}]
      } else {
        acc = [...acc, empty_data, empty_data,{...rec}]
      }
      return acc
    }, [])


    var ws1 = XLSX.utils.json_to_sheet(p1Data);
    XLSX.utils.book_append_sheet(wb , ws1 , "V1_p.1");

    var p2Data = apiData.map( x => ({...x , move_in : "" , move_out: "" , CBM:"", subWeight: x.weight})).reduce((acc,rec) => {
      const itemsInAcc = acc.filter(a=>a.weight === rec.weight && a.length === rec.length && a.width === rec.width && a.height === rec.height  && a.display_name === rec.display_name && a.sonumber === rec.sonumber && a.awb === rec.awb)
      if (itemsInAcc.length > 0) {
        itemsInAcc[0].qty = (+itemsInAcc[0].qty) + (+rec.qty)
        itemsInAcc[0].subWeight = (+itemsInAcc[0].subWeight) + (+rec.subWeight) 
        itemsInAcc[0].vol_cbm = (+itemsInAcc[0].vol_cbm) + (+rec.vol_cbm)

      } else if(acc.length <= 0){
        acc = [...acc,{...rec}]
      }else if (acc.length > 0 && acc[acc.length-1].sonumber == rec.sonumber && acc[acc.length-1].display_name == rec.display_name) {
        acc = [...acc,{...rec}]
      }
      else  {
        acc = [...acc, empty_data, empty_data,{...rec}]
      }
    
      return acc
    }, []).map( x => ({...x , move_in : "" , move_out: "" , CBM:"", weight: x.subWeight? x.subWeight:x.weight}))


    p2Data.forEach(element => {
      delete element.subWeight;
    })
    
    var ws2 = XLSX.utils.json_to_sheet(p2Data);
    XLSX.utils.book_append_sheet(wb , ws2 , "V1_p.2");


    var p3Data = apiData.map( x => ({...x , move_in : "" , move_out: "" ,length:"" , width:"", height:"", CBM:""})).reduce((acc,rec) => {
      const itemsInAcc = acc.filter(a=> a.display_name === rec.display_name && a.sonumber === rec.sonumber && a.awb === rec.awb)
      if (itemsInAcc.length > 0) {
        itemsInAcc[0].qty = (+itemsInAcc[0].qty) + (+rec.qty)
        itemsInAcc[0].weight = (+itemsInAcc[0].weight) + (+rec.weight)
        itemsInAcc[0].vol_cbm = (+itemsInAcc[0].vol_cbm) + (+rec.vol_cbm)
      } else if(acc.length <= 0){
        acc = [...acc,{...rec}]
      }else if (acc.length > 0 && acc[acc.length-1].sonumber == rec.sonumber && acc[acc.length-1].display_name == rec.display_name) {
        acc = [...acc,{...rec}]
      }
      else {
        acc = [...acc, empty_data ,empty_data,{...rec}]
      }
      return acc
    }, [])


    var ws3 = XLSX.utils.json_to_sheet(p3Data);
    XLSX.utils.book_append_sheet(wb , ws3 , "V1_p.3");

    var excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    var data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, username +"_" + searchSonumber + full_date + fileExtension);
  };

  return (
    <div align = 'right' > 
    <button type="button" className="btn btn-success" onClick={(e) => exportToCSV(apiData, username, searchSonumber ,isShowSearchExcel)}>Export Excel by search<br/>匯出搜尋資料</button>
    </div>
  );
};

export default OrderListExportToExcel;