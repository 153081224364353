//upload x-ray image to AWS S.3
import { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation,useHistory
} from "react-router-dom";
import { InputGroup, Container, Row, Col, Modal, Button, NavItem, ButtonGroup, ToggleButton } from "react-bootstrap";
import folderLogo from './img/folder_2.png'
import DownloadImages from './downloadImages';
import DeleteImages from './deleteImages';
import { Collapse } from 'bootstrap';

const ViewImage = () => {

  const { query } = useLocation();


  //const [description, setDescription] = useState("")
  const [description, setDescription] = useState("")
  const [userType, setUserType] = useState("")
  const [images, setImages] = useState([])
  const [order_id, setOrder_id] = useState(0)
  const [remark, setRemark] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [awb, setAwb] = useState("")
  const [flag, setFlag] = useState("0");
  //console.log('staff page upload: ', order_id, query)
  const [checked, setChecked] = useState(false);
  const [moveInDeleteChecked, setMoveInDeleteChecked] = useState(false);
  const [weighingDeleteChecked, setweighingDeleteChecked] = useState(false);
  const [IACSSChecked, setIACSSChecked] = useState(false);
  const [xRayDeleteChecked, setXRayDeleteChecked] = useState(false);
  const history = useHistory();

  const initQuery = async () => {
    if (query != null || typeof (query) !== "undefined") {
      
      setDescription(query.sonumber)
      setUserType(query.userType)
      if (typeof (query.order_id) !== "undefined") {
        setOrder_id(query.order_id)
      } else if (typeof (query.orderlist) !== "undefined" && query.orderlist != null && query.orderlist.length > 0) {
        setOrder_id(query.orderlist[0].order_id)
      }
      setAwb(query.awb)
      setRemark(query.remark)
      setCompanyName(query.companyName)
      setCompanyDisplayName(query.companyDisplayName)
      
    } else {
     // window.location.reload();
     if(localStorage.userAccount == "staff" || localStorage.userAccount == "admin"){
      history.push({ pathname: `/staff/orderlist/` });
     }else if(localStorage.userAccount == "client"){
      history.push({ pathname: `/client/ordertracking/` });
     }
      
    }
  }


  const imagename = async () => {
    try {
      if (flag == 0) {
        if(description==""|| companyName==""){
          return;
        }
        const response1 = await fetch(`https://iacss.smtglobal.com.hk:3001/api/imageKeys/${description}/${companyName}`);
        const jsonData1 = await response1.json();
        //console.log(jsonData1)
        setImages(jsonData1);
        setFlag("1");
      }
    } catch (err) {
      console.error(err.message);
    }
  };


  useEffect(() => {
    initQuery()
  }, [query]);

  useEffect(() => {
    imagename();
  }, [description]);

  return (

    <div>
      <div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </div>
      <br></br>
      <h3>View Photo 檢視相片</h3> <br />
      <h3>SO number: {description}</h3> <br />
      {userType == ("staff"|| "admin") ?
        <h3>Company 公司: {companyDisplayName}</h3>
        : <h3>AWB: {awb}</h3>
      }
      <br></br>


      <br></br>
      <p align="right">
        {userType == "staff" ?
          <Link to="/staff/orderlist">
            <button type="button" className="btn btn-dark ml-3">
              Back to Order List 返回訂單表
            </button>
          </Link>
          :
          <Link to="/client/ordertracking">
            <button type="button" className="btn btn-dark ml-3">
              Back to Order List 返回訂單表
            </button>
          </Link>
        }
      </p>

      <Row >
        <Col xs="auto">
          <label for="inputAWB">Remarks 備註:</label> &nbsp;
        </Col>
        <Col sm={9} >
          <input
            type="text"
            className="form-control col-md-10"
            id="inputRemark"
            maxlength="30"
            value={remark}
            // onChange={e => setRemark(e.target.value)}
            readOnly={true}
          />
        </Col>
      </Row>
      <br></br>
      <br></br>
      {moveInDeleteChecked || weighingDeleteChecked || IACSSChecked || xRayDeleteChecked ?
        <Row>
          <p align="right">
            {/*
            <ButtonGroup>
              <ToggleButton
                id="toggle-check"
                type="checkbox"
                variant="danger"
                checked={checked}
                value="1"
                onChange={(e) => setChecked(e.currentTarget.checked)}
              >
                Choose Mode<br></br>選擇模式
              </ToggleButton>
            </ButtonGroup>
            */
            }
            <DeleteImages apiData={images} userType={userType} username={companyName} searchSonumber={description} moveInDeleteChecked={moveInDeleteChecked} weighingDeleteChecked={weighingDeleteChecked} IACSSChecked={IACSSChecked} xRayDeleteChecked={xRayDeleteChecked} /> &nbsp;
            <DownloadImages apiData={images} userType={userType} username={companyName} searchSonumber={description} moveInDeleteChecked={moveInDeleteChecked} weighingDeleteChecked={weighingDeleteChecked} IACSSChecked={IACSSChecked} xRayDeleteChecked={xRayDeleteChecked} />
          </p>
        </Row> : null
      }
      <br></br>

      <br></br>
      {/*
                        <Row>
                            {images.map(image =>
                                flag === '1' ? (
                                    <Col xs={4} md={0}>
                                        <div key={image}>
                                            <img src={`https://iacss.smtglobal.com.hk:3001/api/images/${image.key}`} width="auto" height="200" />
                                        </div>
                                    </Col>
                                ) : null)}
                        </Row>
        */}

      <ButtonGroup>
        {userType == "staff" ?
          <Link to={{ pathname: `/staff/viewFolderImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName,companyDisplayName: companyDisplayName , awb: awb, userType: userType, photoType: "Move-In" } }}>
            <Button variant="white" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>Move in 入倉</Button>
          </Link> :
          <Link to={{ pathname: `/client/viewFolderImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName, awb: awb, userType: userType, photoType: "Move-In" } }}>
            <Button variant="#bfdfe3" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>Move in 入倉</Button>
          </Link>
        }
        <input
          id="toggle-check"
          type="checkbox"
          variant="light"
          checked={moveInDeleteChecked}
          value="1"
          onChange={(e) => setMoveInDeleteChecked(e.currentTarget.checked)}
        >
        </input>
      </ButtonGroup>






      <ButtonGroup>
        {userType == "staff" ?
          <Link to={{ pathname: `/staff/viewFolderImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName, companyDisplayName: companyDisplayName , awb: awb, userType: userType, photoType: "Weighing" } }}>
            <Button variant="white" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>Weighing 磅相</Button>
          </Link> :
          <Link to={{ pathname: `/client/viewFolderImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName, awb: awb, userType: userType, photoType: "Weighing" } }}>
            <Button variant="#bfdfe3" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>Weighing 磅相</Button>
          </Link>
        }

        <input
          id="toggle-check"
          type="checkbox"
          variant="light"
          checked={weighingDeleteChecked}
          value="1"
          onChange={(e) => setweighingDeleteChecked(e.currentTarget.checked)}
        >
        </input>
      </ButtonGroup>



      <ButtonGroup>

        {userType == "staff" ?
          <Link to={{ pathname: `/staff/viewFolderImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName,companyDisplayName: companyDisplayName , awb: awb, userType: userType, photoType: "Surface" } }}>
            <Button variant="white" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>IACSS 相機</Button>
          </Link> :
          <Link to={{ pathname: `/client/viewFolderImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName, awb: awb, userType: userType, photoType: "Surface" } }}>
            <Button variant="#bfdfe3" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>IACSS 相機</Button>
          </Link>
        }

        <input
          id="toggle-check"
          type="checkbox"
          variant="light"
          checked={IACSSChecked}
          value="1"
          onChange={(e) => setIACSSChecked(e.currentTarget.checked)}
        >
        </input>
      </ButtonGroup>


      <ButtonGroup>

        {userType == "staff" ?
          <Link to={{ pathname: `/staff/viewFolderImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName,companyDisplayName: companyDisplayName , awb: awb, userType: userType, photoType: "X-Ray" } }}>
            <Button variant="white" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>X-ray X光</Button>
          </Link> :
          <Link to={{ pathname: `/client/viewFolderImage/`, query: { order_id: order_id, sonumber: description, remark: remark, companyName: companyName, awb: awb, userType: userType, photoType: "X-Ray" } }}>
            <Button variant="#bfdfe3" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>X-ray X光</Button>
          </Link>
        }

        <input
          id="toggle-check"
          type="checkbox"
          variant="light"
          checked={xRayDeleteChecked}
          value="1"
          onChange={(e) => setXRayDeleteChecked(e.currentTarget.checked)}
        >
        </input>
      </ButtonGroup>


    </div>


  );
};

export default ViewImage;
