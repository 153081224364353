import React, { Fragment, useState ,useEffect} from "react";
import { produce } from "immer";
import {InputGroup, Container, Row, Col , Modal , Button} from "react-bootstrap";

const MutiEditConfirmPop = ( {orderlist,change,getsonumber,resetOrder,remark ,popshow ,validation,awb ,checkValid,companyId}) => {
  
  const showPop = popshow;
  const baseUrl = `https://iacss.smtglobal.com.hk:3001`
  const [show, setShow] = useState(popshow);
  const [orders, setOrder] = useState([orderlist]);
  const [validOrder, setValidOrder] = useState(false);

  const handleClose = () => {
    validation(false)
    setShow(false);
  }
  const handleShow = () =>{ 
    validation(false)
    console.log(orderlist)
    
    if (orderlist && orderlist.length >0) {
      if (orderlist && (orderlist[0].companyname == ""|| orderlist[0].sonumber == "")) {
        console.log("1")
      validation(true)
      return
      }
    } else{
      console.log("2")
      return
    }

    for (var i = 0; i < orderlist.length; i++) {
      if (orderlist[i].move_in_day < 48) {
        if (
          ((orderlist[i].length != null && orderlist[i].length.length > 0) && !isNaN(orderlist[i].length)) &&
          ((orderlist[i].height != null && orderlist[i].height.length > 0) && !isNaN(orderlist[i].height)) &&
          ((orderlist[i].width != null && orderlist[i].width.length > 0) && !isNaN(orderlist[i].width)) &&
          (!isNaN(orderlist[i].weight)) &&
          ((orderlist[i].qty != null && orderlist[i].qty.length > 0) && !isNaN(orderlist[i].qty))
        ) {
          if (orderlist[i].checkbox_record == 1 ) {
            if((orderlist[i].weight != null && orderlist[i].weight.length > 0) && !isNaN(orderlist[i].weight)){
              console.log("ValidOrder")
            }else{
              checkValid(orderlist)
              validation(true)
              console.log("InvalidOrder")
              return
            }
          }
          console.log("ValidOrder")

        } else if (
          ((orderlist[i].length == null || orderlist[i].length.length <= 0)) &&
          ((orderlist[i].height == null || orderlist[i].height.length <= 0)) &&
          ((orderlist[i].width == null || orderlist[i].width.length <= 0)) &&
          ((orderlist[i].weight == null || orderlist[i].weight.length <= 0)) &&
          ((orderlist[i].qty == null || orderlist[i].qty.length <= 0))

        ) {
          console.log("IngoreOrder")
        }
        else {
          checkValid(orderlist)
          validation(true)
          console.log("InvalidOrder")
          return
        }
        console.log((orderlist[i].length != null && orderlist[i].length.length > 0) && !isNaN(orderlist[i].length))

      }
    }

    if (awb && awb.length < 11 || isNaN(awb)) {
      validation(true)
      return
    } 

   
    
   // checkValid(orderlist)
  //  if(!validOrder){
  //  validation(true)
   // return
  //  }
    setShow(true);
  }

  useEffect(() => {
    if(popshow){
    setOrder(true)
    }else{
      setOrder(false)
    }
   },show);
 

  

  //const handleEditClose = () => editShow(false);


    const confirmUpdate = async (e)=>{
      orderlist.filter(orderlist =>orderlist.order_id!=null).map(orderlist => { 
        console.log(orderlist)
        updateorderlist(e ,orderlist.order_id,orderlist.sonumber, orderlist.qty, awb, orderlist.length, orderlist.weight, orderlist.height, orderlist.width, orderlist.companyname, orderlist.checkbox_record);
      })

      orderlist.filter(orderlist =>orderlist.order_id==null).map(orderlist => { 
        console.log(orderlist)
        submitOrder(e ,orderlist.order_id,orderlist.sonumber, orderlist.qty, awb, orderlist.length, orderlist.weight, orderlist.height, orderlist.width, orderlist.companyname,companyId,orderlist.checkbox_record);
      })

      submitAwb(e, orderlist[0].sonumber, orderlist[0].companyname , awb);
      submitRemark(e, orderlist[0].sonumber, orderlist[0].companyname , remark);

      resetOrder([])
      setTimeout(() => {  getsonumber(orderlist[0].companyname,orderlist[0].sonumber); }, 2000);
      //change(true);
      handleClose();

    }

    const updateorderlist = async (e,order_id,sonumber, qty, awb, length, weight, height, width, companyname, checkbox_record) => {
      console.log(checkbox_record)
        e.preventDefault();
        try {
          if (checkbox_record == -1) {
            console.log('update2');
            if(isNaN(weight) || weight == ""){
              weight = null;
            }
            const body = { sonumber, qty, awb, length, weight, height, width, companyname};
            const response = await fetch(
              baseUrl+`/api/orderlist/${order_id}`,
              {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
              }
            );
            
          //  setChange(true);
            }else if (checkbox_record == 1) {
                console.log('update3')
            const body = { sonumber, qty, awb, length, weight, height, width, companyname };
            const response = await fetch(
              baseUrl + `/api/orderlist/move_out/${order_id}`,
              {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
              }
            );
           // setChange(true);
          }else {
            console.log('update order fail')
          }
         
       //  handleEditClose();
        } catch (err) {
          console.error(err.message);
        }
      };


      const submitOrder = async (e,order_id,sonumber, qty, awb, length, weight, height, width, companyname,companyId, checkbox_record) => {
        e.preventDefault();
        try{
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("jwt_token", localStorage.token);
            if(isNaN(weight) || weight == ""){
              weight = null;
            }
            const body = { sonumber , companyname, companyId ,qty, awb, length, weight, height, width};
            if(sonumber=="" || companyname=="" ||qty=="" || length=="" ||height=="" ||width==""){
                console.log("Fail to insert invalid data")
            }else{
                const response = await fetch(baseUrl+`/api/orderlist`, {
                   method: "POST",
                   headers: myHeaders,
                   body: JSON.stringify(body), 
                
                });
                const parseResponse = await response.json();
                console.log(parseResponse);
            }
        } catch (err){
            console.error(err.message);
        }
    };

    const submitAwb = async (e,sonumber, companyname, awb) => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("jwt_token", localStorage.token);
      const body = {sonumber,companyname,awb};
      const response = await fetch(baseUrl+`/api/orderlist/Awb/update`, {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(body), 
     
     });
     const parseResponse = await response.json();
     console.log(parseResponse);
    }

    const submitRemark = async (e,sonumber, companyname, remark) => {
      console.log(remark);
      console.log(companyname);
      console.log(sonumber);
     // e.preventDefault();
      try{

        const response = await fetch(baseUrl+`/api/remark/${companyname}/${sonumber}`);
        const jsonData = await response.json();
        console.log("updateRemark" +jsonData);
        console.log(jsonData);
        if(jsonData!=null && jsonData.remark!=null){
          console.log("updateRemark1");
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("jwt_token", localStorage.token);
          const body = {remark};
          if(sonumber=="" || companyname=="" ||remark==""){
              console.log("Fail to insert invalid data")
          }else{
              const response = await fetch(baseUrl+`/api/remark/update/${companyname}/${sonumber}`, {
                 method: "PUT",
                 headers: myHeaders,
                 body: JSON.stringify(body), 
              
              });
              const parseResponse = await response.json();
              console.log(parseResponse);
          }
        }else{
          console.log("updateRemark2");
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("jwt_token", localStorage.token);
          const body = { sonumber , companyname, remark};
          if(sonumber=="" || companyname=="" ||remark==""){
              console.log("Fail to insert invalid data")
          }else{
              const response = await fetch(baseUrl+`/api/remark`, {
                 method: "POST",
                 headers: myHeaders,
                 body: JSON.stringify(body), 
              
              });
              const parseResponse = await response.json();
              console.log(parseResponse);
          }

        }
      } catch (err){
          console.error(err.message);
      }
  };


return (
<Fragment>

    <Button variant="primary" onClick={handleShow}>
        Update 更新
    </Button>

    
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm edit 確認編輯</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div> Confirm to update.
        </div>
        <div>確認更新。
        </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary"  onClick={e => confirmUpdate(e)} data-dismiss="modal">
            Confirm 確定
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel 取消
          </Button>
        </Modal.Footer>
      </Modal>
</Fragment>        
)
};

export default MutiEditConfirmPop;
