//upload x-ray image to AWS S.3
import { Fragment, useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation, useHistory
} from "react-router-dom";
import { InputGroup, Container, Row, Col, Modal, Button, NavItem, ButtonGroup, ToggleButton } from "react-bootstrap";
import folderLogo from './img/folder_2.png'

const OperatorImageAndSizeMain = () => {

  const { query } = useLocation();
  const [sonumber, setDescription] = useState("")
  const [order_id, setOrder_id] = useState(0)
  const [userType, setUserType] = useState("")
  const [increment, setIncrement] = useState(0)
  const [companyname, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [companyId, setCompanyId] = useState("")
  const [bulkRecordUid, setBulkRecordUid] = useState("")
  const [weight, setWeight] = useState(0)


  const baseUrl = `https://18.138.41.145:3001`
  const history = useHistory()

  const initQuery = async () => {
    console.log(query.companyName)
    console.log(query.companyDisplayName)
    console.log(query.companyId)
    if (query != null || typeof (query) !== "undefined") {
      setDescription(query.sonumber)
      setUserType(query.userType)
      setIncrement(query.increment)
      setCompanyName(query.companyName)
      setCompanyDisplayName(query.companyDisplayName)
      setOrder_id(query.order_id)
      setBulkRecordUid(query.bulkRecordUid)
      setWeight(query.weight)
      setCompanyId(query.companyId)
    } else {
      window.location.reload();
    }
  }


  useEffect(() => {
    initQuery()
  }, [query]);

  return (

    <div>

      <br></br>
      <p align="right">
        {userType == "operator" ?
          <Link to={{ pathname: `/operator/operatorPalletMain`, query: { sonumber: sonumber, companyDisplayName: companyDisplayName, companyId: companyId, companyName: companyname, userType: "operator" } }}>
            <button type="button" className="btn btn-dark ml-3">
              Back to previous page <br></br>返回上一頁
            </button>
          </Link> :

          <Link to="/staff/orderlist">
            <button type="button" className="btn btn-dark ml-3">
              Back to Order List 返回訂單表
            </button>
          </Link>
        }
      </p>
      <br></br>
      <Row>
        <h3 style={{ fontWeight: 'bold' }}> View {"&"} Upload Images 檢視及上傳相片
        </h3> <br /><br /><br />
        <h3>SO Pallet_id: {sonumber + "-" + increment}</h3> <br /><br />
        <h3>Company 公司: {companyDisplayName}</h3><br /><br />
        <Col xs="auto" sm="auto">
          <h3>Size尺寸:</h3>
        </Col>
        <Col xs="auto" sm="auto">
          <Link to={{ pathname: `/operator/operatorPalletSizeInputMain/`, query: { order_id: order_id, sonumber: sonumber, bulkRecordUid: bulkRecordUid, weight: weight, increment: increment, companyDisplayName: companyDisplayName, companyname: companyname, userType: userType } }}>
            <button type="button" className="btn btn-primary ml-3">
              Input 輸入
            </button>
          </Link>
        </Col>
      </Row>


      <br></br>

      <Link to={{ pathname: `/${userType}/operatorFolderItemImage/`, query: { order_id: order_id, sonumber: sonumber, increment: increment, bulkRecordUid: bulkRecordUid, companyDisplayName: companyDisplayName, companyName: companyname, userType: userType, weight: weight, photoType: "Move-In" } }}>
        <Button variant="#f7d8ab" style={{ marginLeft: '2.5rem' }} >  <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>Move in 入倉</Button>
      </Link>



      <Link to={{ pathname: `/${userType}/operatorFolderItemImage/`, query: { order_id: order_id, sonumber: sonumber, increment: increment, bulkRecordUid: bulkRecordUid, companyDisplayName: companyDisplayName, companyName: companyname, userType: userType, weight: weight, photoType: "Weighing" } }}>
        <Button variant="#f7d8ab" style={{ marginLeft: '2.5rem' }} > <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>Weighing 磅相</Button>
      </Link>

      {userType == "staff" ?
        <Link to={{ pathname: `/${userType}/operatorFolderItemImage/`, query: { order_id: order_id, sonumber: sonumber, increment: increment, bulkRecordUid: bulkRecordUid, companyDisplayName: companyDisplayName, companyName: companyname, userType: userType, weight: weight, photoType: "Surface" } }}>
          <Button variant="#f7d8ab" style={{ marginLeft: '2.5rem' }} > <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>IACSS 系統</Button>
        </Link> :
        null
      }

      {userType == "staff" ?
        <Link to={{ pathname: `/${userType}/operatorFolderItemImage/`, query: { order_id: order_id, sonumber: sonumber, increment: increment, bulkRecordUid: bulkRecordUid, companyDisplayName: companyDisplayName, companyName: companyname, userType: userType, weight: weight, photoType: "X-Ray" } }}>
          <Button variant="#f7d8ab" style={{ marginLeft: '2.5rem' }} > <img src={folderLogo} width="150" height="150" alt="logo" /> <br></br>X-ray X光</Button>
        </Link>
        :
        null
      }






    </div >


  );
};

export default OperatorImageAndSizeMain;
