import React, { Fragment, useState } from "react";
import {InputGroup, Container, Row, Col , Modal , Button, NavItem} from "react-bootstrap";
import EditConfirmPop from "./editConfirmPop";


const ClientEdit = ({confirmSource, orderlist, user, userdisplayName ,setChange }) => {
  const [sonumber, setsonumber] = useState(orderlist.sonumber);
  const [qty, setqty] = useState(orderlist.qty);
  const [weight, setweight] = useState(orderlist.weight);
  const [height, setheight] = useState(orderlist.height);
  const [length, setlength] = useState(orderlist.length);
  const [width, setwidth] = useState(orderlist.width);
  const [remark, setRemark] = useState(orderlist.remark);
  const [awb, setawb] = useState(orderlist.awb);
  const [companyname, setcompanyname] = useState(user);
  const [images, setImages] = useState([]);
  //checkbox
  const [checkbox_item, setCheckbox_item] = useState(0);
  const [checkbox_status, setCheckbox_status] = useState({});
  const [checkbox_record, setCheckbox_record] = useState('');
  const [checkbox_init, setCheckbox_init] = useState(() => get_checkbox_init());
  const [clean_move_out, setClean_move_out] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () =>  {
    setAwbValidation(true);
    setShow(false);
  }
  const handleShow = () => {
    setawb(orderlist.awb);
    setRemark(orderlist.remark);
    setShow(true);
  }

  const [awb_validation, setAwbValidation] = useState(true);

  const baseUrl = `https://iacss.smtglobal.com.hk:3001`

  //To see is the SO number has moved out
  function get_checkbox_init (){
    if (orderlist.move_out == null){
      return false;
    } else if (orderlist.move_out != null){
      return true;
    }
  }

  const Checkbox = ({ type = "checkbox", name, checked = checkbox_init, onChange }) => {
    //console.log("Checkbox: ", name, checked);  
    setCheckbox_record(checked);
    //check if there is move out record, there will be not checkbox

    return (<input type={type} name={name} checked={checked} onChange={onChange} disabled='True' />);
  };

  const handleChange = event => {
    setCheckbox_status({
      ...checkbox_status,
      [event.target.name]: event.target.checked
    });
  };

  const checkboxes = [
    {
      name: "Move out 出倉 ",
      key: "move_out_checkbox",
      label: "Move out checkbox"
    }
  ];

  const imagename = async ( sonumber ) => { 
    const response1 = await fetch(baseUrl+`/api/key/${sonumber}`);
    const jsonData1 = await response1.json();
   //console.log(jsonData1)
    setImages(jsonData1);
  };    
  
  const updateorderlsit = async e => {
    e.preventDefault();
    try {
      if (checkbox_record == false) {
        const body = { sonumber, qty, awb, length, weight, height, width, companyname, clean_move_out };
        const response = await fetch(
          baseUrl+`/api/orderlist/${orderlist.order_id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
          }
        );
        
        setChange(true);
        }else if (checkbox_record == true) {
        const body = { sonumber, qty, awb, length, weight, height, width, companyname };
        const response = await fetch(
          baseUrl + `/api/orderlist/move_out/${orderlist.order_id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
          }
        );
        setChange(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Fragment>

    <Button variant="primary" onClick={handleShow}>
        Edit 編輯
    </Button>

    <Modal size="xl" show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Edit  編輯</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div class="form-row">
            <div class="form-group col-md-4">
            <label for="inputSO">SO number</label>
              <input
                type="text"
                className="form-control"
                id = "inputSO"
                maxlength="8"
                value={sonumber}
                onChange={e => setsonumber(e.target.value)}
                readOnly="true"
              />
              </div>
              <div class="form-group col-md-2">
              <label>Move in 入倉</label>
                <input 
                className="form-control" 
                value={orderlist.move_in} 
                readOnly='True'></input>
              </div>
              <div class="form-group col-md-2">
              <label>Move out 出倉</label>
                <input 
                className="form-control" 
                value={orderlist.move_out} 
                readOnly='True'></input>
              </div>

              <div class="form-group col-md-2">
                  {/*<input name="move_out_check" type="checkbox" checked={check_box_status2}
                  onClick={() => { 
                    if (checkbox_item == 0) { setCheckbox_item(1); }
                    else if (checkbox_item == 1) { setCheckbox_item(0); }
                  }}
                />*/}
                 {checkboxes.map(item => (
                    <label key={item.key}>
                      {item.name}
                      <Checkbox
                        name={item.name}
                        checked={checkbox_status[item.name]}
                        onChange={handleChange}
                      />
                    </label>
                  ))}                
              </div>
              </div>

              <div class="form-row">
              <div class="form-group col-md-4">
              <label for="inputAWB">AWB</label> &nbsp;
              {!awb_validation ?<label style={ {color:'red'}}>(Invalid AWB , Must be a 11-digit number)</label> :null}
              <input
                type="text"
                className="form-control"
                id = "inputAWB"
                maxlength="11"
                value={awb}
                onChange={e => setawb(e.target.value)}
              />
              </div>
              <div class="form-group col-md-4">
              <label for="inputCompany">Company name 公司名稱</label>
              <input
                type="text"
                className="form-control"
                id = "inputCompany"
                maxlength="20"
                value={userdisplayName}
               // onChange={e => setcompanyname(e.target.value)}
                readOnly="true"
              />
              </div>
              </div>

              <div class="form-row">
              <div class="form-group col-md-2">
              <label for="inputL">L 長 (mm)</label>
              <input
                type="text"
                className="form-control"
                id = "inputL"
                maxlength="10"
                value={length}
                onChange={e => setlength(e.target.value)}
                readOnly="true"
              />
              </div>
              <div class="form-group col-md-2">
              <label for="inputW">W 寬 (mm)</label>
              <input
                type="text"
                className="form-control"
                id = "inputW"
                maxlength="10"
                value={width}
                onChange={e => setwidth(e.target.value)}
                readOnly="true"
              />
              </div>
              <div class="form-group col-md-2">
              <label for="inputH">H 高 (mm)</label>
              <input
                type="text"
                className="form-control"
                id = "inputH"
                maxlength="10"
                value={height}
                onChange={e => setheight(e.target.value)}
                readOnly="true"
              />
              </div>
              <div class="form-group col-md-2">
              <label for="inputQTY">QTY 數量</label>
              <input
                type="text"
                className="form-control"
                id = "inputQTY"
                maxlength="10"
                value={qty}
                onChange={e => setqty(e.target.value)}
                readOnly="true"
              />
              </div>
              <div class="form-group col-md-2">
              <label for="inputWeight">Weight 重量（kg）</label>
              <input
                type="text"
                className="form-control"
                id = "inputWeight"
                maxlength="10"
                value={weight}
                onChange={e => setweight(e.target.value)}
                readOnly="true"
              />
              </div>

              <div class="form-group col-md-10">
              <label for="inputRemarks">Remarks 備註:</label>
              <input
                type="text"
                className="form-control"
                id = "inputRemark"
                maxlength="180"
                onChange={e => setRemark(e.target.value)}
                value={remark}
              />
              </div>

              </div>
              
          {/* image displayer */}
            <Row> 
            {images.map( image =>
            (      
            <Col xs={6} md={4}>
              <div key={image}>           
              <img src={baseUrl+`/api/images/${image.key}`}  width="300" height="auto"/>
              </div>   
            </Col>
            ))}</Row>
      
        </Modal.Body>

        <Modal.Footer>
        <EditConfirmPop confirmSource = {confirmSource} orderlist = {orderlist} setChange ={setChange} checkbox_record = {checkbox_record} editShow ={handleClose} order_id = {orderlist.order_id} sonumber ={sonumber} qty ={qty} awb={awb} length={length} weight={weight} height={height} width={width} companyname={companyname} clean_move_out={clean_move_out} awbValidation={setAwbValidation} from_client_page = 'T' remark={remark}/>
        

          <Button variant="secondary" onClick={handleClose}>
            Close 關閉
          </Button>
         
        </Modal.Footer>
      </Modal>
     
   
    </Fragment>
  );
};

export default ClientEdit;
