//upload x-ray image to AWS S.3
import { useState, useEffect, useRef, useCallback } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Link, useLocation
} from "react-router-dom";
import { InputGroup, Container, Row, Col, Modal, Button, NavItem, Form, ButtonGroup } from "react-bootstrap";
import folderLogo from './img/folder_2.png'
import { file } from 'jszip';
import OperatorItemImageUploadConfirmPop from "./operatorItemImageUploadConfirmPop";
import WebcamImage from './webCamImage';
import * as Icon from "react-icons/ti";
import Webcam from "react-webcam";


const OperatorItemImageUpload = () => {

  const { query } = useLocation();


  //const [description, setDescription] = useState("")
  const [description, setDescription] = useState("")
  const [userType, setUserType] = useState("")
  const [photoType, setPhotoType] = useState("")
  const [images, setImages] = useState([])
  const [order_id, setOrder_id] = useState(0)
  const [remark, setRemark] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [companyDisplayName, setCompanyDisplayName] = useState("")
  const [flag, setFlag] = useState("0");
  const [files, setFiles] = useState([]);
  const [change, setChange] = useState(false);
  //console.log('staff page upload: ', order_id, query)
  const [img, setImg] = useState(null);
  const [webfiles, setWebFiles] = useState([]);
  const [webfile, setWebFile] = useState();
  const [bulkRecordUid, setBulkRecordUid] = useState("")
  const [increment, setIncrement] = useState(0)
  const webcamRef = useRef(null);
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);

  const videoConstraints = {
    facingMode: facingMode,
  };

  const initQuery = async () => {
    if (query != null || typeof (query) !== "undefined") {
      console.log(query);
      setDescription(query.sonumber)
      setUserType(query.userType)
      setPhotoType(query.photoType)
      if (!isNaN(query.order_id)) {
        console.log(query.order_id);
        setOrder_id(query.order_id)
      }
      setRemark(query.remark)
      setCompanyName(query.companyName)
      setCompanyDisplayName(query.companyDisplayName)
      setBulkRecordUid(query.bulkRecordUid)
      setIncrement(query.increment)
    } else {
      window.location.reload();
    }
  }

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const fileSelected = event => {
    const file = event.target.files
    var curFileList = []
    for (let i = 0; i < file.length; i++) {
      curFileList[i] = file[i]
    }
    console.log(files)
    curFileList.push.apply(curFileList, files);
    console.log(curFileList)
    console.log(files)
    curFileList = removeDuplicates(curFileList, 'name');

    setFiles(curFileList)
    setFlag("1");
    // document.getElementById('file').value = '';
  }

  const localUrl = (flie) => {
    console.log("show")
    const localUrl = URL.createObjectURL(flie)
    return localUrl
  }

  const removeImage = (id, size) => {
    // this is the line that you are looking for
    setFiles((oldState) => oldState.filter((item) => item.name !== id && item.size !== size));
  };

  const reset = async () => {
    setFiles([])
    document.getElementById('file').value = '';
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const base64Data = imageSrc.replace(/^data:image\/\w+;base64,/, '');
    const blob = b64toBlob(base64Data, 'image/jpeg');
    setImg(imageSrc);
    const file = new File([blob], "webcam" + Date.now() + ".jpg", { type: "image/jpeg" })
    setWebFile(file)
  }, [webcamRef]);

  const updateFile = () => {
    if (webfile == undefined) return
    var curFileList = []
    curFileList.push(webfile);
    curFileList.push.apply(curFileList, files);
    setFiles(curFileList)
    setFlag("1");
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const handleClick = useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);

  useEffect(() => {
    initQuery()
  }, [query]);

  useEffect(() => {
    reset()
  }, [change]);

  useEffect(() => {
    updateFile()
  }, [webfile]);

  useEffect(() => {
    console.log(files)
  }, [files]);

  return (

    <div>

      <h3>{photoType == "Move-In" ? "Upload Move In Images 上傳入倉相片" : null}
        {photoType == "Weighing" ? "Upload Weighing Images 上傳磅相相片" : null}
        {photoType == "Surface" ? "Upload IACSS Images 上傳IACSS系統相片" : null}
        {photoType == "X-Ray" ? "Upload X-ray Images 上傳X光相片" : null}
      </h3> <br></br>
      <h3>SO pallet_id: {description + "-" + increment}</h3> <br />
      <h3>Company 公司: {companyDisplayName}</h3>
      <br></br>


      <br></br>
      <p align="right">
        {userType == "operator" ?
          <Link to={{ pathname: `/operator/operatorFolderItemImage/`, query: { order_id: order_id, bulkRecordUid: bulkRecordUid, increment: increment, sonumber: description, companyDisplayName: companyDisplayName, companyName: companyName, userType: userType, photoType: photoType } }}>
            <button type="button" className="btn btn-dark ml-3">
              {
                photoType == "Move-In" ? "Back to Move in Photos 返回入倉相片" :
                  photoType == "Weighing" ? "Back to weighing Photos 返回磅相相片" :
                    photoType == "Surface" ? "Back to IACSS Photos 返回IACSS系統相片" :
                      photoType == "X-Ray" ? "Back to X-ray Photo 返回X光相片" :
                        "Back to View Photo 返回檢視相片"
              }
            </button>
          </Link> :
          <Link to={{ pathname: `/staff/operatorFolderImage/`, query: { order_id: order_id, sonumber: description, companyDisplayName: companyDisplayName, companyName: companyName, userType: userType, photoType: photoType } }}>
            <button type="button" className="btn btn-dark ml-3">
              Back to View Photo 返回檢視相片
            </button>
          </Link>
        }
      </p>


      <br></br>
      <br></br>

      <Form.Group controlId="formFileMultiple" className="mb-3">
        <Form.Control id="file" style={{ color: 'transparent', width: '120px' }} type="file" multiple accept="image/*" onChange={fileSelected} />
      </Form.Group>

      <br></br>
      <button className="webcamSwitch__button" onClick={handleClick}>Switch camera 轉換鏡頭</button>
      <div >
        <>
          <Webcam
            className="webcamCapture"
            audio={false}
            mirrored={facingMode===FACING_MODE_USER}
            // height={200}
            //width={200}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
          <br></br>
          <br></br>
          <button className="webcamCapture__button" onClick={capture}>Capture photo 拍照</button>
        </>
      </div>

      <Row>
        {files.map(file =>
          flag === '1' ? (
            <Col xs="auto" sm={3}>

              <div key={file.name}>
                <img src={localUrl(file)} style={{ marginTop: "15%" }} width="150" height="150" />
                <Icon.TiDelete size={20} onClick={() => removeImage(file.name, file.size)}></Icon.TiDelete>
              </div>
            </Col>
          ) : null)}
      </Row>
      <br></br>

      <Row>
        <p align="right">

          <OperatorItemImageUploadConfirmPop orderId={order_id} bulkRecordUid={bulkRecordUid} increment={increment} sonumber={description} companyDisplayName={companyDisplayName} company={companyName} files={files} userType={userType} photoType={photoType} setChange={setChange} /> &nbsp;

          <Button sm={2} variant="dark" onClick={e => (reset(e))}
          >Reset 重置
          </Button>
        </p>
      </Row>

    </div>


  );
};

export default OperatorItemImageUpload;
